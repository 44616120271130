<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Editar usuario</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.role != 'admin' && usuario.role != 'admin-agec'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard flex-column" v-if="usuario.role == 'admin' || usuario.role == 'admin-agec'">
          <h3 class="divCard__title">Usuario a editar:</h3>
          <p>{{ profileEmail }}</p>
          <div class="col-12 px-0">
            <form @submit.prevent="updateProfile()" class="d-flex flex-wrap">
              <div class="col-12 col-md-6 d-flex flex-wrap">
                <div class="col-12 col-md-6">
                  <div class="form-group ">
                    <label for="name">Nombre</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model.trim="$v.profileName.$model"
                      :class="{'is-invalid': $v.profileName.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.profileName.maxLength">Máximo 20 carácteres</small>
                  </div>
                  <div class="form-group ">
                    <label for="surname">Apellido</label>
                    <input
                      type="text"
                      class="form-control"
                      id="surname"
                      v-model.trim="$v.profileSurname.$model"
                      :class="{'is-invalid': $v.profileSurname.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.profileSurname.maxLength">Máximo 20 carácteres</small>
                  </div>
                  <div class="form-group ">
                    <label for="dni">Número de documento</label>
                    <input
                      type="text"
                      class="form-control"
                      id="dni"
                      v-model.trim="$v.profileDni.$model"
                      :class="{'is-invalid': $v.profileDni.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.profileDni.maxLength">Máximo 20 carácteres</small>
                    <small class="text-danger msg-error" v-if="!$v.profileDni.numeric">Ingrese sólo números sin puntos ni espacios</small>
                  </div>
                  <div class="form-group">
                    <label for="phone">Número de teléfono móvil</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      v-model.trim="$v.profilePhone.$model"
                      :class="{'is-invalid': $v.profilePhone.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.profilePhone.maxLength">Máximo 20 carácteres</small>
                    <small class="text-danger msg-error" v-if="!$v.profilePhone.numeric">Ingrese sólo números sin puntos ni espacios</small>
                  </div>
                </div>
                <div class="col-12 col-md-6 ptc">
                  <div :class="{'alert alert-success': profileState == true, 'alert alert-danger': profileState == false}" role="alert">
                    <div class="custom-control custom-switch ">
                      <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="profileState" />
                      <label class="custom-control-label" for="customSwitch1" v-if="profileState">Activo</label>
                      <label class="custom-control-label" for="customSwitch1" v-if="!profileState">Inactivo</label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="phone">Número de Socio</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      v-model.trim="$v.profileNumber.$model"
                      :class="{'is-invalid': $v.profileNumber.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.profileNumber.maxLength">Máximo 10 carácteres</small>
                    <small class="text-danger msg-error" v-if="!$v.profileNumber.numeric">Ingrese sólo números sin puntos ni espacios</small>
                  </div>
                  <div class="form-group">
                    <label for="employer">Empleador</label>
                    <b-form-select v-model="profileEmployer" :options="options" id="employer" class="form-control"></b-form-select>
                  </div>
                  <div class="form-group">
                    <label for="datepicker-date">Fecha de nacimiento</label>
                    <input type="date" id="datepicker-date" v-model.trim="profileDate" class="form-control" required />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-wrap" v-if="profileEmployer !== ''">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="phone">Número de Legajo</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      v-model.trim="$v.profileLegajo.$model"
                      :class="{'is-invalid': $v.profileLegajo.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.profileLegajo.maxLength">Máximo 10 carácteres</small>
                    <small class="text-danger msg-error" v-if="!$v.profileLegajo.numeric">Ingrese sólo números sin puntos ni espacios</small>
                  </div>
                  <div class="form-group">
                    <label for="datepicker-ingreso">Fecha de ingreso</label>
                    <input
                      type="date"
                      id="datepicker-ingreso"
                      v-model.trim="profileIngreso"
                      class="form-control"
                      required
                      placeholder="ingrese una fecha"
                    />
                  </div>
                  <div class="form-group">
                    <label for="datepicker-engreso">Fecha de egreso</label>
                    <input type="date" id="datepicker-egreso" v-model.trim="profileEgreso" class="form-control" placeholder="ingrese una fecha" />
                  </div>
                  <div class="form-group">
                    <label for="modality">Modalidad</label>
                    <b-form-select v-model="profileModality" :options="optionsModality" id="modality" class="form-control" required></b-form-select>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="category">Categoria</label>
                    <input type="text" class="form-control" id="category" v-model="profileCategory" />
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-end flex-wrap">
                <div
                  v-if="send"
                  :class="{'alert alert-success w-100 mt-3 mb-0': !sendError, 'alert alert-danger w-100 mt-3 mb-0': sendError}"
                  role="alert"
                >
                  <p class="mb-0" v-if="sendError">Error al guardar los datos, vuelva a intentarlo en unos instantes</p>
                  <p class="mb-0" v-if="!sendError">Datos guardados exitosamente</p>
                </div>
                <div class="col-12 col-md-3 pt-3 d-flex">
                  <button type="button" class="btn btn-danger btn-block mr-3" v-b-modal="'delete'">
                    Eliminar
                  </button>
                  <button type="submit" class="btn btn-primary btn-block mt-0" :class="{disabled: !desactivar}" :disabled="!desactivar">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <b-modal id="delete" ok-only ok-title="Cerrar" title="Confirmar" size="sm">
            <div class="d-flex flex-column align-items-center">
              <p class="text-center">¿Esta seguro de eliminar el usuario {{ profileEmail }}?</p>
              <button type="button" class="btn btn-danger" @click="deleteUsuario()">Si, Eliminar</button>
            </div>
          </b-modal>
          <b-modal id="deleteError" ok-only ok-title="Cerrar" title="Error" size="sm">
            <div class="d-flex flex-column align-items-center">
              <p class="text-center">Se produjo un error al eliminar el usuario</p>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {db, auth} from "../firebase";
import router from "../router";
import {required, email, sameAs, minLength, maxLength, numeric} from "vuelidate/lib/validators";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "Editar",
  components: {LayoutPanel},
  data() {
    return {
      id: this.$route.params.id,
      errorDelete: false,
      profileName: "",
      profileSurname: "",
      profileEmail: "",
      profileState: "",
      profileDni: "",
      profilePhone: "",
      profileNumber: "",
      profileRole: "",
      profileEmployer: "",
      profileLegajo: "",
      profileIngreso: "",
      profileEgreso: "",
      profileModality: "",
      profileCategory: "",
      profileDate: "",
      loadingLocal: false,
      sendError: false,
      send: false,
      options: [
        {value: "", text: "Ninguno"},
        {value: "agec", text: "Socio-AGEC"},
        {value: "mutual", text: "Socio-Mutual"},
      ],
      optionsModality: [
        {value: "fulltime", text: "Full-Time"},
        {value: "parttime", text: "Part-Time"},
        {value: "otro", text: "Otra"},
      ],
      optionsCategory: [
        {value: "fulltime", text: "Full-Time"},
        {value: "parttime", text: "Part-Time"},
        {value: "otro", text: "Otra"},
      ],
    };
  },
  computed: {
    ...mapState(["usuario", "carga"]),
    desactivar() {
      return (
        !this.$v.profileName.$invalid &&
        !this.$v.profileSurname.$invalid &&
        !this.$v.profileDni.$invalid &&
        !this.$v.profilePhone.$invalid &&
        !this.$v.profileNumber.$invalid
      );
    },
  },

  validations: {
    profileName: {
      required,
      maxLength: maxLength(20),
    },
    profileSurname: {
      required,
      maxLength: maxLength(20),
    },
    profileDni: {
      required,
      maxLength: maxLength(20),
      numeric,
    },
    profilePhone: {
      required,
      maxLength: maxLength(20),
      numeric,
    },
    profileNumber: {
      required,
      maxLength: maxLength(10),
      numeric,
    },
    profileLegajo: {
      required,
      maxLength: maxLength(10),
      numeric,
    },
    profileCategory: {
      required,
    },
    profileModality: {
      required,
    },
  },
  methods: {
    deleteUsuario() {
      this.errorDelete = false;
      this.loadingLocal = true;
      db.collection("users")
        .doc(this.$route.params.id)
        .delete();
      router.push("/usuarios");
      this.loadingLocal = false;
    },
    getProfile() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .doc(this.$route.params.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const profileTemp = {
                email: doc.data().mail,
                uid: this.$route.params.id,
                name: doc.data().name,
                surname: doc.data().surname,
                role: doc.data().role,
                number: doc.data().number,
                state: doc.data().state,
                dni: doc.data().dni,
                phone: doc.data().phone,
                address: doc.data().address,
                employer: doc.data().employer,
                date: doc.data().date,
                legajo: doc.data().legajo,
                modality: doc.data().modality,
                ingreso: doc.data().ingreso,
                egreso: doc.data().egreso,
                category: doc.data().category,
              };
              this.profileEmail = profileTemp.email;
              this.profileSurname = profileTemp.surname;
              this.profileRole = profileTemp.role;
              this.profileNumber = profileTemp.number;
              this.profileDni = profileTemp.dni;
              this.profileName = profileTemp.name;
              this.profilePhone = profileTemp.phone;
              this.profileLegajo = profileTemp.legajo;
              this.profileEmployer = profileTemp.employer;
              this.profileModality = profileTemp.modality;
              this.profileIngreso = profileTemp.ingreso;
              this.profileEgreso = profileTemp.egreso;
              this.profileCategory = profileTemp.category;
              this.profileDate = profileTemp.date;
              this.loadingLocal = false;
              if (profileTemp.state == "activo") {
                this.profileState = true;
              } else {
                this.profileState = false;
              }
            } else {
              this.loadingLocal = false;
            }
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
    updateProfile() {
      let tempState = "";
      let tmpRole = "user";
      if (this.profileState == true) {
        tempState = "activo";
      } else {
        tempState = "inactivo";
      }
      if (this.profileEmployer !== "") {
        tmpRole = "employee";
      }
      if (this.profileEgreso === undefined) {
        this.profileEgreso = null;
      }
      this.loadingLocal = true;
      if (this.usuario !== null && (this.usuario.role == "admin" || this.usuario.role == "admin-agec")) {
        db.collection("users")
          .doc(this.$route.params.id)
          .update({
            name: this.profileName,
            surname: this.profileSurname,
            dni: this.profileDni,
            phone: this.profilePhone,
            number: this.profileNumber,
            state: tempState,
            employer: this.profileEmployer,
            legajo: this.profileLegajo,
            modality: this.profileModality,
            ingreso: this.profileIngreso,
            egreso: this.profileEgreso,
            category: this.profileCategory,
            date: this.profileDate,
            role: tmpRole,
          })
          .then((doc) => {
            var formData = new FormData();
            formData.append("email", this.profileEmail);
            if (this.profileState == true) {
              fetch("https://mutualmercantil.ar/post-activo.php", {
                method: "POST",
                body: formData,
              });
            }
            this.loadingLocal = false;
            this.send = true;
            this.sendError = false;
          })
          .catch((error) => {
            this.loadingLocal = false;
            this.send = true;
            this.sendError = true;
          });
      }
    },
  },
  created() {
    this.getProfile();
  },
};
</script>
<style lang="scss">
@import "../scss/styles.scss";
.ptc {
  padding-top: 0.85rem;
}
.btn-block {
  padding: 14px 35px;
}
.modal-footer {
  justify-content: center !important;
}
</style>
