var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPanel',[(_vm.loadingLocal || _vm.carga)?_c('div',{staticClass:"loading"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e(),(!_vm.carga)?_c('div',{staticClass:"page"},[_c('h2',{staticClass:"page__title"},[_vm._v("Socios Adherentes")]),_c('div',{staticClass:"col-12 px-2"},[(_vm.code != '576' || _vm.usuario.employer == 'Socio Adherente')?_c('div',{staticClass:"divCard"},[_c('div',{staticClass:"msgInvalid"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'user-slash']}}),_c('h3',[_vm._v("Sin permisos")]),_c('p',[_vm._v(" Su usuario no tiene permiso suficiente para ver este contenido. ")])],1)]):_vm._e(),(_vm.code == '576' && _vm.usuario.employer !== 'Socio Adherente')?_c('div',{staticClass:"divCard"},[_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('router-link',{staticClass:"btn-success btn",attrs:{"to":{name: 'RegistroAdherente', params: {number: 1000 + _vm.usersArray.length + 1}}}},[_vm._v("Cargar Adherente")])],1),_c('div',{staticClass:"col-12 px-0"},[_c('h3',{staticClass:"divCard__title"},[_vm._v("Lista de socios adherentes")]),_c('div',[_c('table',{staticClass:"table"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Estado")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nombre")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(String(_vm.datePrevPrev) .substring(3) .replaceAll("/", "-"))+" ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(String(_vm.datePrev) .substring(3) .replaceAll("/", "-"))+" ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(String(_vm.date) .substring(3) .replaceAll("/", "-"))+" ")])])]),_c('tbody',_vm._l((_vm.usersArray),function(user,index){return _c('tr',{key:index},[_c('th',{staticClass:"text-center",attrs:{"scope":"row"}},[_vm._v(_vm._s(user.number))]),_c('td',[_c('i',{class:{
                        activo:
                          user.state == 'activo' &&
                          user.pagos.filter(
                            function (pago) { return pago.id ==
                              String(_vm.date)
                                .substring(3)
                                .replaceAll('/', '-'); }
                          ).length > 0,
                        inactivo:
                          user.state == 'activo' &&
                          !user.pagos.filter(
                            function (pago) { return pago.id ==
                              String(_vm.date)
                                .substring(3)
                                .replaceAll('/', '-'); }
                          ).length > 0,
                        pendiente:
                          user.state == 'inactivo' &&
                          !user.pagos.filter(
                            function (pago) { return pago.id ==
                              String(_vm.date)
                                .substring(3)
                                .replaceAll('/', '-'); }
                          ).length > 0,
                      }})]),_c('td',[_c('span',{staticClass:"d-inline-block",attrs:{"tabindex":"0","data-toggle":"tooltip","title":user.pagos[0].fecha + ' ' + user.pagos[0].registrante}},[_vm._v(" "+_vm._s(user.surname)+", "+_vm._s(user.name)+" ")])]),_c('td',[(
                        user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.datePrevPrev)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        ).length > 0
                      )?_c('span',{staticClass:"d-inline-block",attrs:{"tabindex":"0","data-toggle":"tooltip","title":user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.datePrevPrev)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        )[0].fecha +
                          ' ' +
                          user.pagos.filter(
                            function (pago) { return pago.id ==
                              String(_vm.datePrevPrev)
                                .substring(3)
                                .replaceAll('/', '-'); }
                          )[0].registrante}},[_c('p',{staticClass:"pagado"},[_vm._v("Pagado")])]):_vm._e(),(
                        !user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.datePrevPrev)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        ).length > 0
                      )?_c('p',{staticClass:"no-pagado"},[_vm._v(" No Pagado ")]):_vm._e()]),_c('td',[(
                        user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.datePrev)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        ).length > 0
                      )?_c('span',{staticClass:"d-inline-block",attrs:{"tabindex":"0","data-toggle":"tooltip","title":user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.datePrev)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        )[0].fecha +
                          ' ' +
                          user.pagos.filter(
                            function (pago) { return pago.id ==
                              String(_vm.datePrev)
                                .substring(3)
                                .replaceAll('/', '-'); }
                          )[0].registrante}},[_c('p',{staticClass:"pagado"},[_vm._v("Pagado")])]):_vm._e(),(
                        !user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.datePrev)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        ).length > 0
                      )?_c('p',{staticClass:"no-pagado"},[_vm._v(" No Pagado ")]):_vm._e()]),_c('td',[(
                        user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.date)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        ).length > 0
                      )?_c('span',{staticClass:"d-inline-block",attrs:{"tabindex":"0","data-toggle":"tooltip","title":user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.date)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        )[0].fecha +
                          ' ' +
                          user.pagos.filter(
                            function (pago) { return pago.id ==
                              String(_vm.date)
                                .substring(3)
                                .replaceAll('/', '-'); }
                          )[0].registrante}},[_c('p',{staticClass:"pagado"},[_vm._v("Pagado")])]):_vm._e(),(
                        !user.pagos.filter(
                          function (pago) { return pago.id ==
                            String(_vm.date)
                              .substring(3)
                              .replaceAll('/', '-'); }
                        ).length > 0
                      )?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('userModal'),expression:"'userModal'"}],staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.setUser(user)}}},[_vm._v(" Pagar ")]):_vm._e()])])}),0)])])])]):_vm._e(),_c('b-modal',{attrs:{"id":"userModal","cancel-title":"Cerrar","ok-title":"Confirmar","title":"Informar pago","size":"lg"},on:{"ok":_vm.updatePayment}},[_c('div',{staticClass:"modal-data"},[_c('div',{staticClass:"d-flex flex-column"},[_c('h5',[_vm._v("Datos del pago a informar")]),_c('p',[_c('b',[_vm._v("Socio: ")]),_vm._v(_vm._s(_vm.selectedUser.surname)+", "+_vm._s(_vm.selectedUser.name))]),_c('p',[_c('b',[_vm._v("Periodo: ")]),_vm._v(_vm._s(String(_vm.date) .substring(3) .replaceAll("/", "-"))+" ")]),_c('p',[_c('b',[_vm._v("Registrante: ")]),_vm._v(_vm._s(_vm.usuario.name)+", "+_vm._s(_vm.usuario.surname))])])])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }