<template>
  <Layout>
    <div class="banner-radio">
      <div class="overlay"></div>
      <h1>Radio</h1>
    </div>
    <div>
      <div class="container py-5">
        <div class="d-flex align-items-strech flex-wrap">
          <div class="col-12 col-md-6  px-0 px-2">
            <div class="divCard card-alerts blue align-items-center">
              <img src="../assets/continental-cordoba-103-5.png" alt="" width="150px" />
              <div class="p-2 pl-4 ">
                <h5 class="card-alerts_title">Continental Córdoba</h5>
                <ul>
                  <li>103.5 Córdoba</li>
                  <li>92.5 FM Río Cuarto.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6  px-0 px-2">
            <div class="divCard card-alerts blue align-items-center justify-content-center">
              <font-awesome-icon :icon="['fas', 'broadcast-tower']" class="pr-3"></font-awesome-icon>
              <h4 class="mb-0">Escuchá en VIVO</h4>
              <div>
                <iframe
                  src="https://www.continentalcba.fm/player/index2.html"
                  width="100px"
                  height="83px"
                  frameborder="no"
                  scrolling="no"
                  style="background-color: transparent;"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="my-4">
          <lightbox cells="4" :items="images"></lightbox>
        </div>
      </div>
    </div>
    <!-- <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Novedades</h2>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
          <div v-for="(item, index) in novedadesArray" :key="index" class="w-100">
            <img v-if="item.category === 'radio'" class="img-novedades" :src="item.imageUrl" alt="novedad" />
          </div>
        </div>
      </div>
    </section> -->
  </Layout>
</template>

<script>
import Slide from "../components/Slide.vue";
import Layout from "../components/Layout.vue";
import {db, storage} from "../firebase";
export default {
  components: {Slide, Layout},
  data() {
    return {
      novedadesArray: [],
      noticias: "",
    };
  },
  methods: {
    getNovedades() {
      this.loadingLocal = true;
      db.collection("novedades")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let item = {imageName: doc.data().imageName, category: doc.data().category, imageUrl: doc.data().imageUrl, id: doc.id};
            this.novedadesArray.push(item);
            this.loadingLocal = false;
          });
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
      this.loadingLocal = false;
    },
  },
  created() {
    this.getNovedades();
  },
};
</script>
<style lang="scss">
@import "../scss/styles.scss";

.banner-radio {
  position: relative;
  width: 100%;
  height: 350px;
  background-image: url("../assets/slide/slide-radio.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.4);
  }
  h1 {
    margin-bottom: 50px;
    color: white;
    font-size: 3rem;
    font-family: $primary-font;
    font-weight: $bold;
    z-index: 50;
  }
}
.play {
  display: flex;
  flex-direction: column;
}
</style>
