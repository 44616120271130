<template>
  <div>
    <div v-if="carga">cargando....</div>
    <div v-if="!carga">
      <h1>admin</h1>
      <p>{{ datos }}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Admin",
  data() {
    return {
      datos: "",
    };
  },
  computed: {
    ...mapState(["usuario", "carga"]),
  },
    methods: {
    getNews() {
      if (this.usuario !== null) {
        this.datos = this.usuario.uid;
      }
    },
  },
  created() {
    this.getNews();
  },
  updated() {
    this.getNews();
  },
  // methods: {
  //   getNews() {
  //     if (this.usuario === null) {
  //       this.datos = "estoy vacio";
  //       setTimeout(() => {
  //         if (this.usuario === null) {
  //           setTimeout(() => (this.datos = this.usuario.uid), 2000);
  //         }
  //         this.datos = this.usuario.uid;
  //       }, 2000);
  //     }
  //     this.datos = this.usuario.uid;
  //   },
  // },
  // created() {
  //   this.getNews();
  // },
};
</script>
