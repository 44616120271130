<template>
  <div>
    <div class="topbar d-flex align-items-center fixed-top">
      <div class=" w-100 d-flex align-items-center justify-content-end">
        <div class="topbar__user" v-if="existeUsuario">
          <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
            <b-dropdown class=" topbar__user__button" right :text="usuario.name">
              <b-dropdown-item to="/configuracion">Editar Perfil</b-dropdown-item>
              <b-dropdown-item @click="cerrarSesion">
                Cerrar sesión
              </b-dropdown-item>
            </b-dropdown>
          </b-button-toolbar>
          <div class="topbar__user__image">
            <img v-if="usuario.foto == ''" src="../assets/avatar.png" alt="perfil" class="profile-picture" />
            <img v-if="usuario.foto != ''" :src="usuario.foto" alt="perfil" class="profile-picture" />
          </div>
        </div>
      </div>
    </div>
    <header class="header fixed-top">
      <div class="container d-flex align-items-center justify-content-between px-0">
        <router-link to="/"><img src="../assets/logo-ammpa.svg" alt="Logo AMMPA"/></router-link>
        <!-- <Navbar /> -->
        <div class="d-flex flex-column">
          <router-link to="/ingresar" v-if="!existeUsuario" class="login-btn mb-2"> INGRESAR</router-link>
          <router-link to="/mi-cuenta" v-if="existeUsuario" class="login-btn"> MI CUENTA</router-link>
          <router-link to="/registro" v-if="!existeUsuario" class="sigin-btn"> REGISTRARSE</router-link>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import Navbar from "./Navbar.vue";
export default {
  name: "Header",
  components: {Navbar},
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters(["existeUsuario"]),
    ...mapState(["usuario"]),
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";



.header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  top: 60px !important;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  &-scrolled {
    top: 0;
  }
  .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    a {
      color: #555555;
    }
  }
  .login-btn {
    margin-right: 0px;
    margin-left: 25px;
    background: #00a9f2;
    color: #fff;
    border-radius: 4px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    &:hover {
      background: #00d5ff;
      color: #fff;
    }
  }
  .sigin-btn {
    margin-right: 0px;
    margin-left: 25px;
    background: $green;
    color: #fff;
    border-radius: 4px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    &:hover {
      background: $greenHover;
      color: #00a9f2;
    }
  }
}
.topbar {
  background: #00a9f2;
  color: #fff;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  z-index: 1000;
  transition: all 0.5s;
  .logo {
    margin: auto;
    margin-left: 80px;
    width: 80px;
  }
  &__scrolled {
    top: -60px;
  }
  i {
    padding-right: 6px;
    line-height: 0;
  }
  &__user {
    position: relative;
    &__button {
      .btn.dropdown-toggle.btn-secondary, .btn.btn-secondary , button{
        background-color: $primaryColor !important;
        padding: 1rem;
        padding-left: 50px !important;
        border-radius: 0 !important;
        height: 60px;
        font-family: $primary-font;
        font-weight: $semi-bold;
        text-overflow: ellipsis;
        width: 120px;
        overflow: hidden;
        &:hover {
          background-color: $green !important;
        }
      }
    }
    &__image {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 10;
      overflow: hidden;
      img.profile-picture {
        width: 35px;
        height: 35px;
      }
    }
  }
  &__buttons {
    &__button {
      position: relative;
      background-color: transparent;
      border: none;
      padding: 1rem 0.5rem;
      color: #fff;
      font-size: 1.4rem!important;
      &:hover {
        color: $primaryColor;
      }
      &.disabled {
        cursor: initial;
        opacity: 0.5;
        &:hover {
          color: #fff;
        }
      }
      &.true {
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: $secondaryColorHover;
          border-radius: 50%;
          position: absolute;
          left: 20px;
          top: 20px;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .header {
    padding: 15px 10px;
  }
}

@media (max-width: 768px) {
  .login-btn,
  .sigin-btn {
    margin: 0 90px 0px 0px;
    padding: 6px 15px;
  }
}
@media screen and (min-width: 996px) {
  .topbar {
    .logo {
      margin: auto;
      margin-left: 20px;
      width: 120px;
    }
    &__user {
      position: relative;
      &__button {
        .btn.dropdown-toggle.btn-secondary {
          padding-left: 60px !important;
          width: auto;
          overflow: hidden;
          &:hover {
            background-color: $green !important;
          }
        }
      }
      &__image {
        left: 15px;
        top: 10px;
      }
    }
  }
}
</style>
