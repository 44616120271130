<template>
  <Layout>
    <div class="banner-turismo">
      <div class="overlay"></div>
      <h1>Turismo</h1>
    </div>
    <div>
      <div class="container py-5">
        <div class="d-flex align-items-strech flex-wrap">
          <div class="col-12  px-0 px-2">
            <div class="divCard card-alerts orange align-items-center">
              <font-awesome-icon :icon="['fas', 'plane']"></font-awesome-icon>
              <div class="p-2 pl-4 ">
                <h5 class="card-alerts_title">Oficina de Turismo</h5>
                <ul>
                  <li>Domicilio: Deán Funes 266</li>
                  <li>Teléfono 0351 423-6418 Int. 141.</li>
                  <li>Horario: Lunes a Viernes de 08 a 17hrs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="my-4">
          <lightbox cells="4" :items="images"></lightbox>
        </div>
      </div>
    </div>
    <!-- <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Novedades</h2>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
          <div v-for="(item, index) in novedadesArray" :key="index" class="w-100">
            <img v-if="item.category === 'turismo'" class="img-novedades" :src="item.imageUrl" alt="novedad" />
          </div>
          

        </div>
      </div>
    </section> -->
  </Layout>
</template>

<script>
import Slide from "../components/Slide.vue";
import Layout from "../components/Layout.vue";
import {db, storage} from "../firebase";
export default {
  components: {Slide, Layout},
  data() {
    return {
      novedadesArray:[],
      images: [
        "https://mutualmercantil.ar/fotos/turismo/turismo01.jpg",
        "https://mutualmercantil.ar/fotos/turismo/turismo02.jpg",
        "https://mutualmercantil.ar/fotos/turismo/turismo03.jpg",
        "https://mutualmercantil.ar/fotos/turismo/turismo01.jpg",
        "https://mutualmercantil.ar/fotos/turismo/turismo02.jpg",
      ],
    };
  },
  methods: {
    getNovedades() {
      this.loadingLocal = true;
      db.collection("novedades")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let item = {imageName: doc.data().imageName, category: doc.data().category, imageUrl: doc.data().imageUrl, id: doc.id};
            this.novedadesArray.push(item);
            this.loadingLocal = false;
          });
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
      this.loadingLocal = false;
    },
  },
  created() {
    this.getNovedades();
  },
};
</script>
<style lang="scss">
@import "../scss/styles.scss";

.banner-turismo {
  position: relative;
  width: 100%;
  height: 350px;
  background-image: url("../assets/slide/slide-turismo.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.4);
  }
  h1 {
    margin-bottom: 50px;
    color: white;
    font-size: 3rem;
    font-family: $primary-font;
    font-weight: $bold;
    z-index: 50;
  }
}
</style>
