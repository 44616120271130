<template>
  <div class="layout-panel">
    <TopBar />
    <div class="layout-panel__content">
      <div class="layout-panel__content__body">
        <slot></slot>
      </div>
      <div class="layout-panel__content__footer">
        2023 © AMMPA
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from "./TopBar.vue";

export default {
  name: "LayoutPanel",
  components: { TopBar },
};
</script>
<style lang="scss">
@import "../scss/styles.scss";

.layout-panel {
  &__content {
    padding: 5rem .2rem 0rem .2rem;
    background-color: #fafbfe;
    min-height: calc(100vh - 0px);
    &__body {
      min-height: calc(100vh - 160px);
    }
    &__footer {
      border-top: 1px solid rgba(152, 166, 173, 0.2);
      width: 100%;
      padding: 1rem 2rem;
      color: #98a6ad;
      text-align: right;
    }
  }
}

@media (min-width: 992px) {
  .layout-panel {
    &__content {
      padding: 5rem 1rem 0rem 270px;
      position: relative;
      &__footer {
        padding: 1rem 2rem;
      }
    }
  }
}
</style>
