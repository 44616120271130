<template>
  <Layout>
    <Slide />
    <!-- ======= Featured Services Section ======= -->
    <section id="featured-services" class="featured-services py-5">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          ><router-link to="/salud">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon">
                <font-awesome-icon
                  :icon="['fas', 'heartbeat']"
                ></font-awesome-icon>
              </div>
              <h4 class="title">Servicio de Salud</h4>
              <p class="description">
                Contamos con centros médicos propios para los asociados
              </p>
            </div>
            </router-link>
          </div>

          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          ><router-link to="/optica">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon">
                <font-awesome-icon
                  :icon="['fas', 'glasses']"
                ></font-awesome-icon>
              </div>
              <h4 class="title">Servicio de Optica</h4>
              <p class="description">Servicio de óptica y beneficios para los asociados a la mutual</p>
            </div>
            </router-link>
          </div>

          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          ><router-link to="/turismo">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon">
                <font-awesome-icon :icon="['fas', 'plane']"></font-awesome-icon>
              </div>
              <h4 class="title">Servicio de Turismo</h4>
              <p class="description">
                Agencia de viajes y turismo con ofertas y promociones especiales
              </p>
            </div>
            </router-link>
          </div>

          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          ><router-link to="/farmacias">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon">
                <font-awesome-icon
                  :icon="['fas', 'plus-square']"
                ></font-awesome-icon>
              </div>
              <h4 class="title">Servicio de Farmacias</h4>
              <p class="description">
                Farmacias en distintos puntos de la ciudad de Córdoba
              </p>
            </div>
            </router-link>
          </div>
        </div>
        <div class="row pt-4">
          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          ><router-link to="/sepelio">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon">
                <font-awesome-icon :icon="['fas', 'cross']"></font-awesome-icon>
              </div>
              <h4 class="title">Servicio de Sepelio</h4>
              <p class="description">
                Con un aporte mensual accedes al servicio especial de sepelios
              </p>
            </div>
            </router-link>
          </div>
          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          ><router-link to="/radio">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon">
                <font-awesome-icon :icon="['fas', 'broadcast-tower']"></font-awesome-icon>
              </div>
              <h4 class="title">Servicio de Radio</h4>
              <p class="description">
                Medios de comunicacion radial para mantenerse informado
              </p>
            </div>
            </router-link>
          </div>
          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          >
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon">
                <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
              </div>
              <h4 class="title"><a href="">Más servicios</a></h4>
              <p class="description">
                Trabajamos cada día para brindar más servicios
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Featured Services Section -->

        <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in">

        <div class="text-center">
          <h3>Aún no te registraste? Ingresa aquí </h3>
          <p> Al registrarte podrás acceder a tu cuenta, ver tu credencial digital, acceder a beneficios y mucho más.</p>
          <router-link to="/registro" class="cta-btn scrollto"
            >Registrate</router-link
          >
        </div>

      </div>
    </section><!-- End Cta Section -->


    <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>AMMPA</h2>
          <p>Asociación Mutual Mercantil del Personal de la Asociación Gremial de Empleados de Comercio de Córdoba</p>
        </div>

        <div class="row">
          <div class="col-12 pt-4 pt-lg-0 content objeto-block" data-aos="fade-left">
            <h3>Nuestro Objeto.</h3>
            <p class="fst-italic">
              Fomentar la ayuda reciproca entre sus miembros para satisfacer sus necesidades, sociales economicas  y un beneficio que estimule la capacidad ahorrativa de los mismos, proporcionando distintos servicios a los socios de acuerdo a la reglamentación estableceida.
            </p>
          </div>
        </div>

      </div>
    </section>

    <section id="services" class="services services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Centros de Salud</h2>
         
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon"><font-awesome-icon :icon="['fas', 'heartbeat']"></font-awesome-icon></div>
            <h4 class="title"><router-link to="/salud#cirs">Centro Integral de Recuperación de la Salud (CIRS)</router-link></h4>
            <p class="description">Centros de especialización médica</p>
          </div>
          <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon"><font-awesome-icon :icon="['fas', 'digital-tachograph']"></font-awesome-icon></div>
            <h4 class="title"><router-link to="/salud">Diagnóstico por Imágenes</router-link></h4>
            <p class="description">Ultima tecnología en diagnóstico por imágenes</p>
          </div>
          <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon"><font-awesome-icon :icon="['fas', 'brain']"></font-awesome-icon></div>
            <h4 class="title"><router-link to="/salud#cip">Centro Integral Psicológico (CIPS)</router-link></h4>
            <p class="description">Especialización en psicología</p>
          </div>
        </div>

      </div>
    </section><!-- End Services Section -->

            <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in">

        <div class="text-center">
          <h3>AMMPA </h3>
          <p> Servicios y beneficios para los asociados.</p>
        </div>

      </div>
    </section><!-- End Cta Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contacto</h2>
        </div>
      </div>
      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-6 m-auto">
            <div class="row">
              <div class="col-md-12">
                <div class="info-box">
                  <font-awesome-icon :icon="['fas', 'map-marker-alt']"></font-awesome-icon>
                  <h3>Nuestra Dirección</h3>
                  <p>Dean Funes Nº266 3er piso -CP: 5000 - Bº Centro - Córdoba</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box mt-4">
                  <font-awesome-icon :icon="['fas', 'envelope']"></font-awesome-icon>
                  <h3>Email </h3>
                  <p>info@mutualmercantil.ar</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box mt-4">
                  <font-awesome-icon :icon="['fas', 'phone']"></font-awesome-icon>
                  <h3>Teléfono</h3>
                  <p>0351-4383148</p>
                </div>
              </div>
            </div>

          </div>

          <!-- <div class="col-lg-6">
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="col form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Su nombre" required>
                </div>
                <div class="col form-group">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Su Email" required>
                </div>
              </div>
              <div class="form-group my-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Asunto" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Mensaje" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Enviar mensaje</button></div>
            </form>
          </div> -->

        </div>

      </div>
    </section><!-- End Contact Section -->
  </Layout>
</template>

<script>
import axios from "axios";
import Slide from "../components/Slide.vue";
import Layout from "../components/Layout.vue";
export default {
  components: {Slide, Layout},
  data() {
    return {
      noticias: "",
    };
  },
};
</script>
<style lang="scss">
@import "../scss/styles.scss";

.img-under {
  width: 80%;
  max-width: 500px;
}
.featured-services .icon-box {
  height: 100%;
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  width: 100%;
}

.featured-services .icon-box::before {
  content: "";
  position: absolute;
  background: #d9f1f2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.featured-services .icon-box:hover::before {
  background: #00a9f2;
  top: 0;
  border-radius: 0px;
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon svg {
  font-size: 48px;
  line-height: 1;
  color: #00a9f2;
  transition: all 0.3s ease-in-out;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #111;
}

.featured-services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.featured-services .icon-box:hover .title a,
.featured-services .icon-box:hover .description {
  color: #fff;
}

.featured-services .icon-box:hover .icon svg {
  color: #fff;
}

.cta {
  background: #00A9F2;
  color: #fff;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  font-size: 28px;
  font-weight: 700;
}

.cta .cta-btn {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #fff;
  color: #00A9F2;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  text-align: center;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #00A9F2;
}

.about .content p:last-child {
  margin-bottom: 0;
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f7fcfc;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #00A9F2;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 20px 0 30px 0;
}

.contact .info-box svg {
  font-size: 3rem;
  color: #00A9F2;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #c5ebec;
  min-width: 50px;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 355px;
  padding-top: 3rem;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #00A9F2;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #00A9F2;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #65c9cd;
}

.services .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.services .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: #00A9F2;
  overflow: hidden;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
}

.services .icon svg {
  font-size: 36px;
  line-height: 0;
}

.services .icon-box:hover .icon {
  box-shadow: 0px 0 25px rgba(63, 187, 192, 0.3);
}

.services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.services .title a {
  color: #444444;
  transition: 0.3s;
}

.services .title a:hover {
  color: #00A9F2;
}

.services .title::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: #00A9F2;
  bottom: 0;
  left: calc(50% - 25px);
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}
.objeto-block{
  background-color: #f7fcfc;
  padding: 2rem!important;
  border-radius: 20px;
  margin: 2rem 0;
}
</style>
