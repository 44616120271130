<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Socios Adherentes</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="code != '576' || usuario.employer == 'Socio Adherente'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard" v-if="code == '576' && usuario.employer !== 'Socio Adherente'">
          <div class="d-flex justify-content-end w-100">
            <router-link :to="{name: 'RegistroAdherente', params: {number: 1000 + usersArray.length + 1}}" class="btn-success btn"
              >Cargar Adherente</router-link
            >
          </div>
          <div class="col-12 px-0">
            <h3 class="divCard__title">Lista de socios adherentes</h3>
            <div>
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" class="text-center">#</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">
                      {{
                        String(datePrevPrev)
                          .substring(3)
                          .replaceAll("/", "-")
                      }}
                    </th>
                    <th scope="col">
                      {{
                        String(datePrev)
                          .substring(3)
                          .replaceAll("/", "-")
                      }}
                    </th>
                    <th scope="col">
                      {{
                        String(date)
                          .substring(3)
                          .replaceAll("/", "-")
                      }}
                    </th>

                    <!-- <th scope="col" class="text-right">Acciones</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in usersArray" :key="index">
                    <th class="text-center" scope="row">{{ user.number }}</th>
                    <td>
                      <i
                        :class="{
                          activo:
                            user.state == 'activo' &&
                            user.pagos.filter(
                              (pago) =>
                                pago.id ==
                                String(date)
                                  .substring(3)
                                  .replaceAll('/', '-')
                            ).length > 0,
                          inactivo:
                            user.state == 'activo' &&
                            !user.pagos.filter(
                              (pago) =>
                                pago.id ==
                                String(date)
                                  .substring(3)
                                  .replaceAll('/', '-')
                            ).length > 0,
                          pendiente:
                            user.state == 'inactivo' &&
                            !user.pagos.filter(
                              (pago) =>
                                pago.id ==
                                String(date)
                                  .substring(3)
                                  .replaceAll('/', '-')
                            ).length > 0,
                        }"
                      ></i>
                    </td>
                    <td>
                      <span class="d-inline-block" tabindex="0" data-toggle="tooltip" :title="user.pagos[0].fecha + ' ' + user.pagos[0].registrante">
                        {{ user.surname }}, {{ user.name }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        v-if="
                          user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(datePrevPrev)
                                .substring(3)
                                .replaceAll('/', '-')
                          ).length > 0
                        "
                        :title="
                          user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(datePrevPrev)
                                .substring(3)
                                .replaceAll('/', '-')
                          )[0].fecha +
                            ' ' +
                            user.pagos.filter(
                              (pago) =>
                                pago.id ==
                                String(datePrevPrev)
                                  .substring(3)
                                  .replaceAll('/', '-')
                            )[0].registrante
                        "
                      >
                        <p class="pagado">Pagado</p>
                      </span>
                      <p
                        class="no-pagado"
                        v-if="
                          !user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(datePrevPrev)
                                .substring(3)
                                .replaceAll('/', '-')
                          ).length > 0
                        "
                      >
                        No Pagado
                      </p>
                    </td>
                    <td>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        v-if="
                          user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(datePrev)
                                .substring(3)
                                .replaceAll('/', '-')
                          ).length > 0
                        "
                        :title="
                          user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(datePrev)
                                .substring(3)
                                .replaceAll('/', '-')
                          )[0].fecha +
                            ' ' +
                            user.pagos.filter(
                              (pago) =>
                                pago.id ==
                                String(datePrev)
                                  .substring(3)
                                  .replaceAll('/', '-')
                            )[0].registrante
                        "
                      >
                        <p class="pagado">Pagado</p>
                      </span>
                      <p
                        class="no-pagado"
                        v-if="
                          !user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(datePrev)
                                .substring(3)
                                .replaceAll('/', '-')
                          ).length > 0
                        "
                      >
                        No Pagado
                      </p>
                    </td>
                    <td>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        v-if="
                          user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(date)
                                .substring(3)
                                .replaceAll('/', '-')
                          ).length > 0
                        "
                        :title="
                          user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(date)
                                .substring(3)
                                .replaceAll('/', '-')
                          )[0].fecha +
                            ' ' +
                            user.pagos.filter(
                              (pago) =>
                                pago.id ==
                                String(date)
                                  .substring(3)
                                  .replaceAll('/', '-')
                            )[0].registrante
                        "
                      >
                        <p class="pagado">Pagado</p>
                      </span>
                      <button
                        v-b-modal="'userModal'"
                        @click="setUser(user)"
                        class="btn btn-primary btn-sm"
                        v-if="
                          !user.pagos.filter(
                            (pago) =>
                              pago.id ==
                              String(date)
                                .substring(3)
                                .replaceAll('/', '-')
                          ).length > 0
                        "
                      >
                        Pagar
                      </button>
                    </td>
                    <!-- <td class="text-right">
                      <button v-b-modal="'userModal'" class="btn-see" @click="getUser(user)">
                        <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
                      </button>
                      <router-link :to="{name: 'Editar', params: {id: user.id}}" class="btn-edit"
                        ><font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                      </router-link>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <b-modal id="userModal" cancel-title="Cerrar" ok-title="Confirmar" @ok="updatePayment" title="Informar pago" size="lg">
          <div class="modal-data">
            <div class="d-flex flex-column">
              <h5>Datos del pago a informar</h5>
              <p><b>Socio: </b>{{ selectedUser.surname }}, {{ selectedUser.name }}</p>
              <p>
                <b>Periodo: </b
                >{{
                  String(date)
                    .substring(3)
                    .replaceAll("/", "-")
                }}
              </p>
              <p><b>Registrante: </b>{{ usuario.name }}, {{ usuario.surname }}</p>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import moment from "moment";
moment.locale("es");
import {mapState, mapActions} from "vuex";
import {db} from "../firebase";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "Users",
  data() {
    return {
      code: null,
      selectedUser: "",
      date: moment().format("L"),
      datePrev: moment()
        .subtract(1, "month")
        .format("L"),
      datePrevPrev: moment()
        .subtract(2, "month")
        .format("L"),
      urlFile: null,
      usersArray: [],
      loadingLocal: false,
      profileName: "",
      profileSurname: "",
      profileEmail: "",
      profileState: "",
      profileDni: "",
      profilePhone: "",
      profileNumber: "",
      profileRole: "",
      profileEmployer: "",
      profileLegajo: "",
      profileIngreso: "",
      profileEgreso: "",
      profileModality: "",
      profileCategory: "",
      profileDate: "",
      profileFoto: "",
      profileAddress: "",
      profilePhone: "",
      profileCivilState: "",
      profileChilds: "",
      profileChildsArray: "",
      profileBeneficios: "",
    };
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
    desactivarDatePrev() {
      return moment().diff(this.datePrev) > 1 ? true : false;
    },
    desactivarDatePrevPrev() {
      return moment().diff(this.datePrevPrev) > 1 ? true : false;
    },
  },
  methods: {
    inputCode() {
      this.code = prompt("Ingrese contraseña de acceso:", "");
    },
    setUser(user) {
      this.selectedUser = user;
    },
    getUsers() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let item = {
                id: doc.id,
                name: doc.data().name,
                surname: doc.data().surname,
                state: doc.data().state,
                employer: doc.data().employer,
                number: doc.data().number,
                pagos: doc.data().pagos,
              };
              this.usersArray.push(item);
              this.loadingLocal = false;
            });
            this.usersArray = this.usersArray.filter((user) => user.employer == "Socio Adherente");
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
    updatePayment() {
      if (this.usuario !== null) {
        this.selectedUser.pagos.push({
          fecha: this.date,
          registrante: `${this.usuario.surname}, ${this.usuario.name}`,
          id: String(this.date)
            .substring(3)
            .replaceAll("/", "-"),
        });
        db.collection("users")
          .doc(this.selectedUser.id)
          .update({
            state: "activo",
            pagos: this.selectedUser.pagos,
          })
          .then((doc) => {
            this.loadingLocal = false;
            this.send = true;
            this.sendError = false;
            this.usersArray = [];
            this.getUsers();
          })
          .catch((error) => {
            this.loadingLocal = false;
            this.send = true;
            this.sendError = true;
            this.usersArray = [];
            this.getUsers();
          });
      }
    },
  },
  created() {
    this.getUsers();
    this.inputCode();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";
.modal-footer {
  .btn.btn-secondary {
    border-radius: 4px !important;
  }
}
.divCard p.pagado {
  color: $statusGreen;
  font-weight: 600;
}
.divCard p.no-pagado {
  color: $statusRed;
  font-weight: 600;
}
.table {
  .thead-dark {
    th {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.75rem 0.3rem;
    }
  }
  td {
    font-size: 0.9rem !important;
    padding: 0.75rem 0.3rem;
  }
  i {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin-left: 20px;
    margin-top: 5px;
    &.activo {
      background-color: $statusGreen;
    }
    &.inactivo {
      background-color: $statusRed;
    }
    &.pendiente {
      background-color: $secondaryColor;
    }
  }
}

.btn-edit {
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
  }
}
.btn-see {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $secondaryColorHover;
  }
  &:hover {
    svg {
      color: $secondaryColorHover;
    }
  }
}

.modal {
  .modal-data {
    display: flex;
    flex-direction: column;
    &__profile {
      display: flex;
      flex-wrap: wrap;
      .profile-picture {
        width: 100%;
        height: auto;
        position: relative;
        &__img {
          width: 100%;
          height: 100%;
        }
      }
      .profile-info {
        padding: 1rem;
        width: 100%;
      }
    }
    &__employee,
    &__family {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .modal {
    .modal-data {
      &__profile {
        flex-wrap: nowrap;
        .profile-picture {
          width: 250px;
          height: 250px;
          &__img {
            width: 100%;
            height: 100%;
          }
        }
        .profile-info {
          width: auto;
        }
      }
      &__employee,
      &__family {
        width: 50%;
      }
    }
  }
}
</style>
