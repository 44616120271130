<template>
  <div class="login">
    <div class="login__box">
      <h1 class="login__box__title">Registrar adherente Nro: {{number}}</h1>
      <form
        id="formulario"
        @submit.prevent="
          crearUsuarioAdherente({
            email: email,
            password: dni,
            name: name,
            surname: surname,
            dni: dni,
            phone: phone,
            address: address,
            date: date,
            number: number, 
            registrantSurname: usuario.surname,
            registrantName: usuario.name,
            user: usuario
          })
        "
        class="login__box__form"
      >
        <div class="form-group">
          
          <label for="email">Correo electrónico</label>
          <input
            name="email"
            type="email"
            class="form-control"
            id="email"
            aria-describedby="emailHelp"
            v-model="$v.email.$model"
            :class="{'is-invalid': $v.email.$error}"
            required
          />
          <small class="text-danger msg-error" v-if="!$v.email.email">El correo debe ser un formato válido</small>
        </div>

        <div class="form-group">
          <label for="name">Nombres</label>
          <input
            name="name"
            type="text"
            class="form-control"
            id="name"
            v-model.trim="$v.name.$model"
            :class="{'is-invalid': $v.name.$error}"
            required
          />
          <small class="text-danger msg-error" v-if="!$v.name.maxLength">Máximo 20 carácteres</small>
        </div>
        <div class="form-group">
          <label for="surname">Apellidos</label>
          <input type="text" class="form-control" id="surname" v-model.trim="$v.surname.$model" :class="{'is-invalid': $v.surname.$error}" required />
          <small class="text-danger msg-error" v-if="!$v.surname.maxLength">Máximo 20 carácteres</small>
        </div>
        <div class="form-group">
          <label for="dni">Número de documento</label>
          <input type="text" class="form-control" id="dni" v-model.trim="$v.dni.$model" :class="{'is-invalid': $v.dni.$error}" required />
          <small class="text-danger msg-error" v-if="!$v.dni.maxLength">Máximo 20 carácteres</small>
          <small class="text-danger msg-error" v-if="!$v.dni.numeric">Ingrese sólo números sin puntos ni espacios</small>
        </div>
        <div class="form-group">
          <label for="phone">Número de teléfono móvil</label>
          <input type="text" class="form-control" id="phone" v-model.trim="$v.phone.$model" :class="{'is-invalid': $v.phone.$error}" required />
          <small class="text-danger msg-error" v-if="!$v.phone.maxLength">Máximo 20 carácteres</small>
          <small class="text-danger msg-error" v-if="!$v.phone.numeric">Ingrese sólo números sin puntos ni espacios</small>
        </div>
        <div class="form-group">
          <label for="phone">Domicilio</label>
          <input type="text" class="form-control" id="address" v-model.trim="$v.address.$model" :class="{'is-invalid': $v.address.$error}" required />
          <small class="text-danger msg-error" v-if="!$v.address.maxLength">Máximo 50 carácteres</small>
        </div>

        <div class="form-group">
          <label for="datepicker">Fecha de nacimiento</label>
          <input type="date" id="bday" name="bday" v-model.trim="$v.date.$model" class="form-control" required placeholder="igrese una fecha">

          <!-- <b-form-datepicker id="datepicker" v-model.trim="$v.date.$model" class="mb-2" required placeholder="igrese una fecha"></b-form-datepicker> -->
        </div>
        <small>Al registrarse su correo electrónico será establecido como su domicilio legal electrónico</small>
        <small class="form-text text-danger ">{{ error }}{{ errorLocal }}</small>
        <div class="login__box__button">
          <button type="submit" class="btn btn-primary" :class="{disabled: !desactivar}" :disabled="!desactivar">
            Registrar
          </button>

         
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import router from '../router'
import {required, email, sameAs, minLength, maxLength, numeric} from "vuelidate/lib/validators";
export default {
  name: "Registro",
  data() {
    return {
      email: "",
      errorLocal: "",
      name: "",
      surname: "",
      dni: "",
      phone: "",
      date: "",
      address: "",
      number: this.$route.params.number
      
    };
  },
  validations: {
    email: {required, email},
    name: {
      required,
      maxLength: maxLength(20),
    },
    surname: {
      required,
      maxLength: maxLength(20),
    },
    dni: {
      required,
      maxLength: maxLength(20),
      numeric,
    },
    phone: {
      required,
      maxLength: maxLength(20),
      numeric,
    },
    address: {
      required,
      maxLength: maxLength(50),
    },
    date: {
      required,
    },
  },
  methods: {
    ...mapActions(["crearUsuarioAdherente"]),
  },
  computed: {
     ...mapState(["usuario", "carga", "error"]),
    
    desactivar() {
      return (
        this.$v.email.required &&
        !this.$v.name.$invalid &&
        !this.$v.surname.$invalid &&
        !this.$v.dni.$invalid &&
        !this.$v.phone.$invalid &&
        !this.$v.address.$invalid &&
        !this.$v.date.$invalid
      );
    },
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.login {
  background: rgb(0, 169, 242);
  background: linear-gradient(90deg, rgba(0, 169, 242, 1) 0%, rgba(77, 200, 222, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  &__box {
    width: 90%;
    background-color: white;
    min-height: calc(100vh - 100px);
    border-radius: 5px;
    box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.3);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__title {
      font-size: $medium;
      font-family: $primary-font;
      margin-bottom: 1rem;
      color: $agecColor;
      text-align: center;
      margin: 2rem auto;
    }
    &__form {
      margin-bottom: 1rem;
    }
    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      button {
        padding: 0.5rem 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (min-width: 996px) {
  .login {
    &__box {
      padding: 2rem 4rem;
      width: 600px;
      height: auto;
    }
  }
}
</style>
