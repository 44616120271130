<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga && !loadingLocal" class="page">
      <h2 class="page__title">Mi Recibo</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.role !== 'employee' || usuario.state === 'inactivo'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role === 'employee' && usuario.state === 'activo'">
          <div class="col-12 px-0">
            <h3 class="divCard__title">Lista de Recibos del legajo: {{ profileLegajo }} de {{ usuario.employer }}</h3>
            <div>
              <table class="table" v-if="recibosArray !== []">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" class="pl-3">Período</th>
                    <th scope="col">Detalle</th>
                    <th scope="col" class="text-right pr-4">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(recibo, index) in recibosArray" :key="index">
                    <th scope="row">{{ recibo.year }} - {{ recibo.month }}</th>
                    <td>{{ recibo.detail }}</td>
                    <td class="text-right pr-4">
                      <button v-b-modal="'confirm-down'" class="btn-down" @click="downloadFile(recibo)">
                        <font-awesome-icon :icon="['fas', 'file-download']"></font-awesome-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <div v-if="error" class="alert alert-danger mt-3 mb-0" role="alert">
                ERROR: el archivo que intenta descargar no existe o no esta disponible
              </div> -->
            </div>
          </div>
        </div>
        <b-modal id="confirm-down" ok-only ok-title="Cerrar" title="Confirmación" size="sm">
          <div class="spinner-border text-primary" role="status" v-if="fileUrl === ''">
            <span class="sr-only">Loading...</span>
          </div>

          <div v-if="fileUrl !== ''">
            <p>¿Esta seguro de que quiere descargar el recibo?</p>

            <a :href="fileUrl" download class="btn btn-success">Si, descargar</a>
          </div>
        </b-modal>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState} from "vuex";
import {db, storage} from "../firebase";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "MiRecibo",
  data() {
    return {
      recibosArray: [],
      error: false,
      loadingLocal: false,
      files: null,
      fileUrl: "",
      profileSurname: "",
      profileRole: "",
      profileNumber: "",
      profileName: "",
      profileLegajo: "",
      profileEmployer: "",
    };
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
  },
  methods: {
    async getProfile() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        await db
          .collection("users")
          .doc(this.usuario.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const profileTemp = {
                name: doc.data().name,
                surname: doc.data().surname,
                role: doc.data().role,
                number: doc.data().number,
                employer: doc.data().employer,
                legajo: doc.data().legajo,
              };
              this.profileSurname = profileTemp.surname;
              this.profileRole = profileTemp.role;
              this.profileNumber = profileTemp.number;
              this.profileName = profileTemp.name;
              this.profileLegajo = profileTemp.legajo;
              this.profileEmployer = profileTemp.employer;
              this.loadingLocal = false;
              if (profileTemp.state == "activo") {
                this.profileState = true;
              } else {
                this.profileState = false;
              }
            } else {
              this.loadingLocal = false;
            }
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
    async downloadFile(recibo) {
      let tmpfile = "";
      let period = recibo.year + "-" + recibo.month + "-" + recibo.detail;

      const desertRef = storage
        .ref()
        .child(this.usuario.employer == "agec" ? "recibos-agec" : "recibos")
        .child(period)
        .child(this.profileLegajo + ".pdf");
      await desertRef
        .getDownloadURL()
        .then(function(url) {
          tmpfile = url;
          this.fileUrl = tmpfile;
          // var xhr = new XMLHttpRequest();
          // xhr.responseType = "blob";
          // xhr.onload = function(event) {
          //   var blob = xhr.response;
          //   var src = url;
          // };
          // xhr.open("get", url, true);
          // xhr.send();
        })
        .catch(function(error) {});
      this.fileUrl = tmpfile;
    },
    async getRecibos() {
      this.loadingLocal = true;
      var recibos = [];
      db.collection(this.usuario.employer == "agec" ? "recibos-agec" : "recibos")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let item = {number: doc.data().number, year: doc.data().year, month: doc.data().month, detail: doc.data().detail};
            recibos.push(item);
          });
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
      if (recibos !== []) {
        this.recibosArray = recibos.sort((a, b) => {
          if (a.year === b.year) {
            return b.month - a.month;
          }
          return b.year - a.year;
        });
      }
      this.loadingLocal = false;
    },
  },
  async created() {
    await this.getProfile();
    this.getRecibos();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.table {
  .thead-dark {
    th {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.75rem 0.3rem;
    }
  }
  td {
    font-size: 0.9rem !important;
    padding: 0.75rem 0.3rem;
  }
  i {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin-left: 20px;
    margin-top: 5px;
    &.activo {
      background-color: $statusGreen;
    }
    &.inactivo {
      background-color: $statusRed;
    }
  }
}

.btn-delete {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $statusRed;
  }
}
.btn-down {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $primaryColor;
  }
}

.btn-block {
  padding: 14px 35px !important;
}
.modal-body {
  text-align: center;
}
.modal-footer {
  justify-content: center;
}
</style>
