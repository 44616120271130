<template>
  <div class="layout">
    <Header />
      <div class="layout__content">
        <slot></slot>
      </div>
    <Footer />
  </div>
</template>
<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "Layout",
  components: { Header, Footer },
};
</script>
<style lang="scss">
  @import '../scss/styles.scss';

.layout{
  &__content{
    padding: 7rem 0rem 2rem 0rem;
  }
}
</style>