<template>
  <div class="login">
    <div class="login__box">
      <img class="login__box__logo" src="../assets/logo-ammpa-iso.svg" alt="Logo AMMPA" />
      <h1 class="login__box__title">Iniciar Sesión</h1>
      <form @submit.prevent="ingresoUsuario({email: email, password: pass})" class="login__box__form">
        <div class="form-group">
          <label for="email">Correo electrónico</label>
          <input
            type="email"
            class="form-control"
            id="email"
            aria-describedby="emailHelp"
            v-model="$v.email.$model"
            :class="{'is-invalid': $v.email.$error}"
            required
          />
          <small class="text-danger msg-error" v-if="!$v.email.email">El correo debe ser un formato válido</small>
        </div>
        <div class="form-group">
          <label for="Password1">Contraseña</label>
          <input type="password" class="form-control" id="Password1" v-model="$v.pass.$model" :class="{'is-invalid': $v.pass.$error}" required />
          <small class="text-danger msg-error" v-if="!$v.pass.minLength">Mínimo 6 carácteres</small>
        </div>
        <b-alert show variant="danger mt-4" v-if="error">Usuario o contraseña incorrercta</b-alert>
        <div class="login__box__button">
          <button class="btn btn-primary" type="submit">Ingresar</button>

          <router-link to="/resetear-password">Olvide mi contraseña</router-link>
          <router-link to="/registro">¿No estas registrado? Ingresá aquí</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {required, email, sameAs, minLength} from "vuelidate/lib/validators";
export default {
  name: "Ingresar",
  data() {
    return {
      email: "",
      pass: "",
    };
  },
  validations: {
    email: {required, email},
    pass: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    ...mapActions(["ingresoUsuario"]),
  },
  computed: {
    ...mapState(["usuario", "carga", "error"]),
  },
};
</script>
<style lang="scss">
@import "../scss/styles.scss";

.login {
  background: rgb(0, 169, 242);
  background: linear-gradient(90deg, rgba(0, 169, 242, 1) 0%, rgba(77, 200, 222, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  &__box {
    width: 90%;
    background-color: white;
    min-height: calc(100vh - 100px);
    border-radius: 5px;
    box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.3);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__logo {
      width: 150px;
      margin: auto;
    }
    &__title {
      font-size: $medium;
      font-family: $primary-font;
      margin-bottom: 1rem;
      color: $agecColor;
      text-align: center;
      margin: 2rem auto;
    }
    &__form {
      margin-bottom: 1rem;
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      button {
        padding: 0.5rem 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (min-width: 996px) {
  .login {
    &__box {
      padding: 2rem 4rem;
      width: 600px;
      height: auto;
    }
  }
}
</style>
