<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="footer__info">
              <h3>AMMPA</h3>
              <p>
                Dean Funes 266- 3er piso -CP: 5000 - Bº Centro <br />
                Córdoba<br /><br />
                <strong>Phone:</strong> 0351-4383148<br />
                <strong>Email:</strong> info@mutualmercantil.ar<br />
              </p>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 footer__links">
            <h4>Navegar</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Mutual</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Servicios</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Beneficios</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Politica de privacidad</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer__links">
            <h4>Usuarios</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i> <a href="#">Ingresar</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Panel de usuario</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a href="#">Empresas</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>AMMPA</span></strong
        >. Todos los derechos reservados
      </div>
      <div class="credits">
        Desarrollado por
        <a href="http://tecnoemprendedores.com.ar/">Tecnoemprendedores</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.footer {
  background: #eeeeee;
  padding: 0 0 30px 0;
  color: #555555;
  font-size: 14px;
  &__top {
    background: #f6f6f6;
    padding: 60px 0 30px 0;
    .footer__info {
      margin-bottom: 30px;
      h3 {
        font-size: 24px;
        margin: 0 0 20px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: "Roboto", sans-serif;
      }
    }
    .social__links {
      a {
        font-size: 18px;
        display: inline-block;
        background: #00a9f2;
        color: #fff;
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 4px;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
        &:hover {
          background: #65c9cd;
          text-decoration: none;
        }
      }
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
      position: relative;
      padding-bottom: 12px;
    }
    .footer__links {
      margin-bottom: 30px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        i {
          padding-right: 2px;
          color: #00a9f2;
          font-size: 18px;
          line-height: 1;
        }
        li {
          padding: 10px 0;
          display: flex;
          align-items: center;
          &:first-child {
            padding-top: 0;
          }
        }
        a {
          color: #555555;
          transition: 0.3s;
          display: inline-block;
          line-height: 1;
          &:hover {
            color: #00a9f2;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    padding-top: 30px;
  }
  .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
  }
}
</style>
