<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Mi Cuenta</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.state == 'inactivo'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin verificar</h3>
            <p>
              Su cuenta se encuentra sin verificar por AMMPA, en un máximo de 48hs hábiles habilitaremos su acceso para visualizar su cuenta. Le
              notificaremos a su correo electrónico una vez que este verificado.
              IMPORTANTE: para poder verificar su cuenta deberá cargar una foto de perfil desde la opcion de configuración, o haciendo click <router-link to="configuracion">aquí</router-link>

            </p>
          </div>
        </div>
        <div class="divCard" v-if="usuario.state == 'activo'">
          <div class="col-12 col-md-6">
            <p class="divCard__welcome">Hola, {{ profile.name }}</p>
            <h3 class="divCard__title">Mis Datos</h3>
            <p><b>Nombre:</b> {{ profile.surname }}, {{ profile.name }}</p>
            <p><b>Email:</b> {{ profile.email }}</p>
            <p><b>Socio N°:</b> {{ profile.number }}</p>
            <p><b>Estado:</b> {{ profile.state }}</p>
          </div>
          <div class="col-12 col-md-6">
            <h3 class="divCard__title">Mi Credencial</h3>
            <div class="credencial">
              <div class="credencial__header">
                <img class="credencial__header__image" src="../assets/logo-ammpa-white.svg" alt="Logo AMMPA" />
              </div>
              <div class="credencial__body">
                <p class="credencial__body__name">{{ profile.surname }}</p>
                <p class="credencial__body__name">{{ profile.name }}</p>
                <p class="credencial__body__text">DNI {{ profile.dni }}</p>
                <p class="credencial__body__socio" :class="profile.state">SOCIO {{ profile.state }}</p>
                <div class="credencial__body__image d-flex py-3">
                  <div class="col-6 px-0">
                    <img v-if="profile.foto == ''" src="../assets/avatar.png" alt="perfil" class="profile-picture" />
                    <img v-if="profile.foto != ''" :src="profile.foto" alt="perfil" class="profile-picture" />
                  </div>
                  <div class="col-6 px-0">
                    <img :src="'http://api.qrserver.com/v1/create-qr-code/?data=https://mutualmercantil.ar/verificacion/' + profile.uid + '&size=100x100'" alt="verificacion" />
                  </div>
                </div>
                <p class="credencial__body__nro-socio">SOCIO {{ profile.number }}</p>
                <p class="credencial__body__nro-socio text-info" v-if="usuario.employer === 'Socio Adherente'"><b>ADHERENTE</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 px-0 d-flex flex-wrap ">
        <div class="col-12 col-md-6 px-0 px-2" v-if="profile.civilState == ''">
          <router-link to="configuracion">
            <div class="divCard card-alerts orange align-items-center">
              <font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon>
              <div class="p-2 pl-4 ">
                <p class="card-alerts_text">Completa la información de tu</p>
                <h5 class="card-alerts_title">Grupo Familiar</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 px-0 px-2" v-if="profile.foto == ''">
          <router-link to="configuracion">
            <div class="divCard card-alerts green align-items-center">
              <font-awesome-icon :icon="['fas', 'portrait']"></font-awesome-icon>
              <div class="p-2 pl-4 ">
                <p class="card-alerts_text">Es necesario que carges tu</p>
                <h5 class="card-alerts_title">Foto carnet</h5>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState} from "vuex";
import {db} from "../firebase";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  components: {LayoutPanel},
  name: "MiCuenta",
  data() {
    return {
      profile: {email: "", uid: "", name: ""},
      loadingLocal: false,
    };
  },
  computed: {
    ...mapState(["usuario", "carga"]),
  },
  methods: {
    getProfile() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .doc(this.usuario.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const profileTemp = {
                email: this.usuario.email,
                uid: this.usuario.uid,
                name: doc.data().name,
                surname: doc.data().surname,
                role: doc.data().role,
                number: doc.data().number,
                state: doc.data().state,
                dni: doc.data().dni,
                foto: doc.data().foto,
                civilState: doc.data().civilState,
              };

              this.profile.email = profileTemp.email;
              this.profile.uid = profileTemp.uid;
              this.profile.surname = profileTemp.surname;
              this.profile.role = profileTemp.role;
              this.profile.number = profileTemp.number;
              this.profile.state = profileTemp.state;
              this.profile.dni = profileTemp.dni;
              this.profile.name = profileTemp.name;
              this.profile.foto = profileTemp.foto;
              this.profile.civilState = profileTemp.civilState;
              this.loadingLocal = false;
            } else {
              // doc.data() will be undefined in this case
 
              this.loadingLocal = false;
            }
          })
          .catch((error) => {

            this.loadingLocal = false;
          });
      }
    },
  },
  created() {
    this.getProfile();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.credencial {
  background-color: $agecColor;
  border-radius: 15px;
  width: 250px;
  height: 380px;
  padding: 10px;
  &__header {
    height: 90px;
    width: 100%;
    overflow: hidden;
    &__image {
      width: 220px;
    }
  }
  &__body {
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    height: 270px;
    width: 100%;
    font-family: $primary-font;
    color: #1a1a1a;
    padding: 10px;
    &__text {
      font-weight: $normal;
      margin-bottom: 0;
      font-size: $small;
      color: $gray;
    }
    &__name {
      font-weight: $bold;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    &__nro-socio {
      font-weight: $bold;
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: center;
    }
    &__socio {
      font-weight: $bold;
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: $small;
      &::after {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 5px;
        content: "";
        position: absolute;
        margin-top: 7px;
        color: $gray;
      }
      &.activo {
        &::after {
          background-color: #10e669;
        }
      }
      &.inactivo {
        &::after {
          background-color: #e63010;
        }
      }
    }
    &__image {
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
