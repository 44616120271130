<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Beneficios {{ this.$route.params.id }}</h2>
      <div class="col-12 px-2 d-flex flex-wrap">
        <div class="col-12 col-md-4 px-0 px-2" v-for="(item, index) in beneficiosArray" :key="index">
          <div class="divCard card-alerts h-100 flex-column flex-nowrap justify-content-between" :class="item.value">
            <div>
              <p class="category" :class="item.category">{{ item.category }}</p>
              <img :src="item.url" alt="" class="card-img" v-if="item.url !== null" />
              <div class="py-3" v-if="item.url === null"></div>
              <div class="p-2 p-4 ">
                <h5 class="card-alerts_title mb-3">{{ item.title }}</h5>
                <p>{{ item.detail }}</p>
              </div>
            </div>
            <div v-if="item.usable">
              <button v-if="!active[index] && !add[index]" class="btn btn-success btn-block" @click="addButton(index)">
                Estoy usando este beneficio
              </button>
              <div v-if="add[index]">
                <div class="form-group">
                  <label>Número de comprobante</label>
                  <input type="text" class="form-control" v-model.trim="nroInput[index]" required />
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-danger" @click="addButton(index)">Cancelar</button>
                  <button class="btn btn-success" @click="addBeneficio(item, index)">Guardar</button>
                </div>
              </div>
              <div v-for="(itemUser, idx) in userBeneficiosArray" :key="idx">
                <div v-if="item.title === itemUser.name">
                  <div v-if="itemUser.active">
                    <p class="check"><font-awesome-icon :icon="['fas', 'check-circle']"></font-awesome-icon>Estas usando este beneficio</p>
                    <p class="nro">Nro de comprobante: {{ itemUser.nro }}</p>
                    <button class="btn btn-primary btn-block" @click="deleteBeneficio(itemUser, idx)">Ya no uso este beneficio</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {db, storage} from "../firebase";
import LayoutPanel from "../components/LayoutPanel.vue";
import router from "../router";
export default {
  name: "Beneficio",
  components: {LayoutPanel},
  data() {
    return {
      id: this.$route.params.id,
      beneficiosArray: [],
      profileEmployer: "",
      loadingLocal: false,
      userBeneficiosArray: [],
      add: [],
      active: [],
      nroInput: [""],
    };
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
  },
  methods: {
    addButton(index) {
      this.add.splice(index, 1, !this.add[index]);
    },
    addBeneficio(item, index) {
      this.loadingLocal = true;
      db.collection("users")
        .doc(this.usuario.uid)
        .collection("beneficios")
        .doc(item.title)
        .set({
          name: item.title,
          active: true,
          nro: this.nroInput[index],
        })
        .then((doc) => {
          this.getBeneficios();
          this.getProfile();
          this.loadingLocal = false;
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
    },
    deleteBeneficio(item, index) {
      this.loadingLocal = true;
      db.collection("users")
        .doc(this.usuario.uid)
        .collection("beneficios")
        .doc(item.name)
        .delete()
        .then(() => {
          this.userBeneficiosArray.splice(index, 1);

          this.getBeneficios();
          this.getProfile();
          this.loadingLocal = false;
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
    },
    getBeneficios() {
      this.beneficiosArray = [];
      this.userBeneficiosArray = [];
      this.active = [];
      this.add = [];
      this.loadingLocal = true;
      db.collection("beneficios")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let item = {
              title: doc.data().title,
              detail: doc.data().detail,
              to: doc.data().to,
              category: doc.data().category,
              url: doc.data().imageUrl,
              usable: doc.data().usable,
            };
            if (item.category === this.$route.params.id && (item.to === "todos" || item.to === this.usuario.employer)) {
              this.beneficiosArray.push(item);
              this.nroInput.push("");
              this.active.push(false);
              this.add.push(false);
            }
            this.loadingLocal = false;
          });
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
      this.loadingLocal = false;
    },
    getProfile() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .doc(this.usuario.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const profileTemp = {
                employer: doc.data().employer,
              };
              db.collection("users")
                .doc(this.usuario.uid)
                .collection("beneficios")
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    let item = {
                      name: doc.data().name,
                      nro: doc.data().nro,
                      active: doc.data().active,
                    };
                    this.userBeneficiosArray.push(item);
                    for (let i = 0; i < this.beneficiosArray.length; i++) {
                      if (this.beneficiosArray[i].title === doc.data().name) {
                        this.active[i] = true;
                      }
                    }
                  });
                })
                .catch((error) => {});
              this.profileEmployer = profileTemp.employer;
              this.loadingLocal = false;
            } else {
              // doc.data() will be undefined in this case
              this.loadingLocal = false;
            }
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
  },
  created() {
    this.getBeneficios();
    this.getProfile();
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.category {
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 5px;
  color: white !important;
  font-weight: $bold;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: wheat;
  &.optica {
    background-color: $green;
  }
  &.generales {
    background-color: royalblue;
  }
  &.turismo {
    background-color: $secondaryColorHover;
  }
  &.farmacias {
    background-color: #33cc66;
  }
  &.radio {
    background-color: $statusRed;
  }
  &.sepelio {
    background-color: $black;
  }
  &.salud {
    background-color: $agecColor;
  }
}
.card-alerts_detail {
  padding-left: 4.5rem;
}
.icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check {
  font-family: $primary-font;
  color: $statusGreen;
  font-weight: $semi-bold;
  margin-bottom: 0.3rem;
  svg {
    font-size: 1.4rem;
    color: $statusGreen;
    margin-right: 0.3rem;
  }
}
.nro {
  color: $black;
  font-size: $small;
  padding-left: 1.8rem;
  margin-bottom: 1rem;
}
</style>
