<template>
  <div class="login">
    <div class="login__box">
      <img class="login__box__logo" src="../assets/logo-ammpa-iso.svg" alt="Logo AMMPA" />
      <h1 class="login__box__title">Resetear su contraseña</h1>
      <p>Para reestablecer su contraseña ingrese su correo electrónico, luego presione el botón Reset, recibirá un correo electronico a la casilla indicada para reestablecer su contraseña.</p>
      <form @submit.prevent="resetPassword()" class="login__box__form">
        <div class="form-group">
          <label for="email">Correo electrónico</label>
          <input
            type="email"
            class="form-control"
            id="email"
            aria-describedby="emailHelp"
            v-model="$v.email.$model"
            :class="{'is-invalid': $v.email.$error}"
            required
          />
          <small class="text-danger msg-error" v-if="!$v.email.email">El correo debe ser un formato válido</small>
        </div>
        <div :class="{'alert alert-success': send == true, 'alert alert-danger': error == true}" role="alert">
          <div class="custom-control custom-switch ">
            <p v-if="error" class="mb-0">Se produjo un error, vuelva a intentarlo</p>
            <p v-if="send" class="mb-0">Se ha enviado el mail exitosamente</p>
          </div>
        </div>
        <div class="login__box__button">
          <button class="btn btn-primary" type="submit">Resetear</button>
          <router-link to="/ingresar">Si recordas tu clave ingresá aquí</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {auth} from "../firebase";
import {required, email, sameAs, minLength} from "vuelidate/lib/validators";
export default {
  name: "Reset",
  data() {
    return {
      email: "",
      error: false,
      send: false,
    };
  },
  validations: {
    email: {required, email},
  },
  methods: {
    resetPassword(usuario) {
      this.loadingLocal = true;
      auth
        .sendPasswordResetEmail(this.email)
        .then((res) => {
          this.loadingLocal = false;
          this.error = false;
          this.send = true;
        })
        .catch((error) => {
          commit("setError", error);
          this.loadingLocal = false;
          this.error = true;
          this.send = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "../scss/styles.scss";

.login {
  background: rgb(0, 169, 242);
  background: linear-gradient(90deg, rgba(0, 169, 242, 1) 0%, rgba(77, 200, 222, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  &__box {
    width: 90%;
    background-color: white;
    min-height: calc(100vh - 100px);
    border-radius: 5px;
    box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.3);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__logo {
      width: 150px;
      margin: auto;
    }
    &__title {
      font-size: $medium;
      font-family: $primary-font;
      margin-bottom: 1rem;
      color: $agecColor;
      text-align: center;
      margin: 2rem auto;
    }
    &__form {
      margin-bottom: 1rem;
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      button {
        padding: 0.5rem 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (min-width: 996px) {
  .login {
    &__box {
      padding: 2rem 4rem;
      width: 600px;
      height: auto;
    }
  }
}
</style>
