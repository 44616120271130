<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Recibos</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.role != 'admin-agec'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role == 'admin-agec'">
          <div class="col-12 px-0">
            <h3 class="divCard__title">Cargar Recibos AGEC</h3>
            <div>
              <form @submit.prevent="uploadRecibos()" class="d-flex flex-column">
                <div class="col-12 d-flex flex-wrap align-items-center">
                  <div class="form-group col-12 col-md-3">
                    <label for="year">Año</label>
                    <b-form-select v-model.trim="$v.year.$model" :options="yearOptions" id="year" class="form-control" required></b-form-select>
                    <small class="text-danger msg-error" v-if="!$v.year.numeric">Ingrese sólo números sin puntos ni espacios</small>
                  </div>
                  <div class="form-group col-12 col-md-3">
                    <label for="month">Mes</label>
                    <b-form-select v-model.trim="$v.month.$model" :options="monthOptions" id="month" class="form-control" required></b-form-select>
                    <small class="text-danger msg-error" v-if="!$v.month.numeric">Ingrese sólo números sin puntos ni espacios</small>
                  </div>
                  <div class="form-group col-12 col-md-3">
                    <label for="name">Detalle</label>
                    <input
                      type="text"
                      class="form-control"
                      id="detail"
                      v-model.trim="$v.detail.$model"
                      :class="{'is-invalid': $v.detail.$error}"
                      required
                    />
                    <small class="text-danger msg-error" v-if="!$v.detail.maxLength">Máximo 50 carácteres</small>
                  </div>
                  <div class="col-12 col-md-3">
                    <input type="file" accept="application/pdf" :multiple="true" ref="boton" class="d-none" @change="buscarImagen($event)" />
                    <button class="btn btn-primary btn-block mb-3 mb-md-0" @click="$refs.boton.click()" type="button">Seleccionar recibos</button>
                  </div>
                  <div class="col-12">
                    <b-alert show variant="primary" v-if="files !== null">
                      <p class="mb-0">Archivos seleccionados:</p>
                      <span v-for="(item, index) in arrayNames" :key="index">
                        <font-awesome-icon :icon="['fas', 'file-pdf']"></font-awesome-icon>
                        <span class="mr-3 ml-1">{{ item }}</span>
                      </span>
                    </b-alert>
                  </div>
                  <div class="col-12 d-flex flex-wrap flex-md-nowrap px-0 px-md-3">
                    <div class="col-12 col-md-9 pl-md-0 mr-md-2">
                      <b-alert show variant="warning" v-if="files !== null">Hay cambios pendientes, debe hacer click en Guardar</b-alert>
                      <div
                        v-if="send"
                        :class="{'alert alert-success w-100 mt-3 mt-md-1 mb-0': !sendError, 'alert alert-danger w-100 mt-3 mt-md-1 mb-0': sendError}"
                        role="alert"
                      >
                        <p class="mb-0" v-if="sendError">Error al guardar los datos, vuelva a intentarlo en unos instantes</p>
                        <p class="mb-0" v-if="!sendError">Datos guardados exitosamente</p>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 pr-md-2">
                      <button type="submit" class="btn btn-primary btn-block mt-0" :class="{disabled: !desactivar}" :disabled="!desactivar">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role == 'admin-agec'">
          <div class="col-12 px-0">
            <h3 class="divCard__title">Lista de Recibos</h3>
            <div>
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" class="pl-3">Año</th>
                    <th scope="col">Mes</th>
                    <th scope="col">Detalle</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col" class="text-right pr-3">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(recibo, index) in recibosArray" :key="index">
                    <td>{{ recibo.year }}</td>
                    <td>{{ recibo.month }}</td>
                    <td>{{ recibo.detail }}</td>
                    <td>{{ recibo.number }}</td>
                    <td class="text-right">
                      <button class="btn-delete" @click="deleteFiles(recibo)">
                        <font-awesome-icon :icon="['fas', 'trash']"></font-awesome-icon>
                      </button>
                      <router-link
                        :to="{name: 'RecibosAgecEditar', params: {id: recibo.year + '-' + recibo.month + '-' + recibo.detail}}"
                        class="btn-edit"
                        ><font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {db, storage} from "../firebase";
import {required, email, sameAs, minLength, maxLength, numeric} from "vuelidate/lib/validators";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "RecibosAgec",
  data() {
    return {
      recibosArray: [],
      arrayNames: [],
      detail: "",
      sendError: false,
      send: false,
      year: "",
      yearOptions: [
        {value: 2021, text: "2021"},
        {value: 2022, text: "2022"},
        {value: 2023, text: "2023"},
        {value: 2024, text: "2024"},
        {value: 2025, text: "2025"},
        {value: 2026, text: "2026"},
        {value: 2027, text: "2027"},
        {value: 2028, text: "2028"},
        {value: 2028, text: "2029"},
        {value: 2028, text: "2030"},
        {value: 2028, text: "2031"},
        {value: 2028, text: "2032"},
      ],
      month: "",
      monthOptions: [
        {value: 1, text: "Enero"},
        {value: 2, text: "Febrero"},
        {value: 3, text: "Marzo"},
        {value: 4, text: "Abril"},
        {value: 5, text: "Mayo"},
        {value: 6, text: "Junio"},
        {value: 7, text: "Julio"},
        {value: 8, text: "Agosto"},
        {value: 9, text: "Septiembre"},
        {value: 10, text: "Octubre"},
        {value: 11, text: "Noviembre"},
        {value: 12, text: "Diciembre"},
      ],
      loadingLocal: false,
      files: null,
    };
  },
  validations: {
    detail: {
      required,
      maxLength: maxLength(50),
    },
    year: {
      required,
      numeric,
    },
    month: {
      required,
      numeric,
    },
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
    desactivar() {
      return !this.$v.detail.$invalid && !this.$v.year.$invalid && !this.$v.month.$invalid && this.file !== null;
    },
  },
  methods: {
    buscarImagen(event) {
      this.files = event.target.files;
      for (var y = 0; y < event.target.files.length; y++) {
        this.arrayNames.push(event.target.files[y].name);
      }
    },
    async uploadRecibos() {
      this.loadingLocal = true;
      let tmpNumber = 0;
      this.recibosArray.map((item) => {
        if (item.year === this.year) {
          if (item.month === this.month) {
            if (item.detail === this.detail) {
              tmpNumber = item.number;
            }
          }
        }
      });
      try {
        for (let index = 0; index < this.files.length; index++) {
          const refImagen = storage
            .ref()
            .child("recibos-agec")
            .child(this.year + "-" + this.month + "-" + this.detail)
            .child(this.files[index].name);
          const res = await refImagen.put(this.files[index]);
          const url = await refImagen.getDownloadURL();
        }

        await db
          .collection("recibos-agec")
          .doc(this.year + "-" + this.month + "-" + this.detail)
          .set({
            year: this.year,
            month: this.month,
            detail: this.detail,
            number: this.files.length + tmpNumber,
          });
        await db
          .collection("users")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().employer === "agec" && doc.data().role === "employee") {
                db.collection("users")
                  .doc(doc.id)
                  .collection("notificaciones")
                  .doc(this.year + "-" + this.month)
                  .set({
                    message: "Se encuentra disponible Recibo del mes " + this.year + "-" + this.month,
                    state: true,
                  });
              }
            });
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
        db.collection("users");
        this.month = "";
        this.year = "";
        this.detail = "";
        this.files = null;
        this.loadingLocal = false;
        this.send = true;
        this.recibosArray = [];
        this.getRecibos();
        this.sendError = false;
      } catch (error) {
        this.loadingLocal = false;
        this.sendError = true;
        this.send = true;
      }

      // this.file = this.myCroppa.generateDataUrl()
    },
    async deleteFiles(name) {
      const desertRef = storage
        .ref()
        .child("recibos-agec")
        .child(name.year + "-" + name.month + "-" + name.detail);
      await desertRef
        .delete()
        .then(function() {
          data();

          // this.loadingLocal = false;
        })
        .catch(function(error) {
          //  this.loadingLocal = false
        });
      db.collection("recibos-agec")
        .doc(name.year + "-" + name.month + "-" + name.detail)
        .delete();
      this.recibosArray = [];
      this.getRecibos();
    },
    getRecibos() {
      this.loadingLocal = true;
      db.collection("recibos-agec")
        .get()
        .then((querySnapshot) => {
          const recibos = [];
          querySnapshot.forEach((doc) => {
            recibos.push({
              number: doc.data().number,
              year: doc.data().year,
              month: doc.data().month,
              detail: doc.data().detail,
            });
          });

          this.recibosArray = recibos.sort((a, b) => {
            if (a.year === b.year) {
              return b.month - a.month;
            }
            return b.year - a.year;
          });

          this.loadingLocal = false;
        })
        .catch((error) => {
          this.loadingLocal = false;
          console.error("Error al obtener recibos:", error);
        });
    },
  },
  created() {
    this.getRecibos();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.table {
  .thead-dark {
    th {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.75rem 0.3rem;
    }
  }
  td {
    font-size: 0.9rem !important;
    padding: 0.75rem 0.3rem;
  }
  i {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    margin-left: 20px;
    margin-top: 5px;
    &.activo {
      background-color: $statusGreen;
    }
    &.inactivo {
      background-color: $statusRed;
    }
  }
}

.btn-edit {
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
  }
}

.btn-block {
  padding: 14px 35px !important;
}

.btn-delete {
  background-color: transparent;
  border: none;
  outline: none;
  padding-right: 1.5rem;
  svg {
    font-size: 1.3rem;
    color: $statusRed;
  }
}
</style>
