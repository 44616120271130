<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Configuración</h2>
      <div class="col-12 px-2">
        <div class="divCard flex-column">
          <div class="col-12 col-xl-8 px-0">
            <form @submit.prevent="updateProfile()" class="d-flex flex-wrap">
              <div class="col-12 col-md-6">
                <h3 class="divCard__title">Mis Datos</h3>
                <p><b>Email:</b> {{ profile.email }}</p>
                <p><b>Nombre:</b> {{ profile.surname }}, {{ profile.name }}</p>
                <div class="form-group">
                  <label for="phone">Número de teléfono móvil</label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    v-model.trim="$v.profilePhone.$model"
                    :class="{'is-invalid': $v.profilePhone.$error}"
                    required
                  />
                  <small class="text-danger msg-error" v-if="!$v.profilePhone.maxLength">Máximo 20 carácteres</small>
                  <small class="text-danger msg-error" v-if="!$v.profilePhone.numeric">Ingrese sólo números sin puntos ni espacios</small>
                </div>
                <div class="form-group">
                  <label for="phone">Domicilio</label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    v-model.trim="$v.address.$model"
                    :class="{'is-invalid': $v.address.$error}"
                    required
                  />
                  <small class="text-danger msg-error" v-if="!$v.address.maxLength">Máximo 50 carácteres</small>
                </div>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-end">
                <h3 class="divCard__title">Imagen de perfil</h3>
                <div class="profile-picture">
                  <img v-if="profile.foto == ''" src="../assets/avatar.png" alt="perfil" class="profile-picture__img" />
                  <img v-if="profile.foto != ''" :src="profile.foto" alt="perfil" class="profile-picture__img" />
                  <croppa
                    v-model="myCroppa"
                    placeholder="Seleccionar imagen"
                    :zoom-speed="3"
                    :width="250"
                    :height="250"
                    :placeholderFontSize="22"
                    placeholderColor="white"
                    :disableRotation="false"
                  ></croppa>
                </div>
                <div class="d-flex mt-5">
                  <button @click="myCroppa.rotate(-1)" type="button" class="btn btn-success mr-3">
                    <font-awesome-icon :icon="['fas', 'undo']"></font-awesome-icon>
                  </button>
                  <button @click="myCroppa.rotate(1)" type="button" class="btn btn-success">
                    <font-awesome-icon :icon="['fas', 'undo']" class="rotate"></font-awesome-icon>
                  </button>
                </div>
              </div>

              <div class="col-12 mt-5 mb-3 justify-content-end d-flex">
                <button class="btn btn-primary btn-block col-12 col-md-4" type="submit">Guardar</button>
              </div>
              <div class="col-12">
                <div
                  v-if="send || pending"
                  :class="{'alert alert-success': !sendError, 'alert alert-danger': sendError, 'alert alert-warning': pending}"
                  role="alert"
                >
                  <p class="mb-0" v-if="sendError">Error al guardar los datos, vuelva a intentarlo en unos instantes</p>
                  <p class="mb-0" v-if="!sendError && !pending">Datos guardados exitosamente</p>
                  <p class="mb-0" v-if="pending">Hay cambios pendientes, debe hacer click en Guardar</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 px-0 d-flex flex-wrap ">
        <div class="col-12 px-2">
          <div class="divCard flex-column">
            <div class="col-12">
              <h3 class="divCard__title">Grupo familiar</h3>
              <form @submit.prevent="updateFamily()" class="d-flex flex-wrap">
                <div class="col-12 col-md-6 d-flex flex-wrap px-0">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="civilState">Estado Civil</label>
                      <b-form-select v-model="civilState" :options="optionsCivilState" id="civilState" class="form-control" required></b-form-select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="childs">Cant. de Hijos</label>
                      <b-form-select v-model="childs" :options="optionsChilds" id="childs" class="form-control" required></b-form-select>
                    </div>
                  </div>
                </div>
                <div class="col-12 px-4" v-for="(children, index) in childs" :key="index">
                  <div class="form-row ">
                    <p class="mb-0 pr-2 pt-4">
                      <b>Hijo {{ (nro = index + 1) }}</b>
                    </p>
                    <div class="form-group col-md-3">
                      <label>Nombre</label>
                      <input type="text" class="form-control" v-model.trim="childsData[index].name" required />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Sexo</label>
                      <b-form-select v-model.trim="childsData[index].sexo" :options="optionsGender" class="form-control" required></b-form-select>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Fecha de nacimiento</label>
                      <input type="date" v-model.trim="childsData[index].date" class="form-control" :required="true" placeholder="ingrese una fecha" />
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-end flex-wrap">
                  <div
                    v-if="sendFamily"
                    :class="{'alert alert-success w-100 mt-3 mb-0': !sendErrorFamily, 'alert alert-danger w-100 mt-3 mb-0': sendErrorFamily}"
                    role="alert"
                  >
                    <p class="mb-0" v-if="sendErrorFamily">Error al guardar los datos, vuelva a intentarlo en unos instantes</p>
                    <p class="mb-0" v-if="!sendErrorFamily">Datos guardados exitosamente</p>
                  </div>
                  <div class="col-12 col-md-3 pt-3 d-flex justify-content-end px-0 px-md-3">
                    <button type="submit" class="btn btn-primary mt-0 btn-block" :class="{disabled: !desactivar}" :disabled="!desactivar">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState} from "vuex";
import {db, storage} from "../firebase";
import {required, maxLength, numeric} from "vuelidate/lib/validators";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  components: {LayoutPanel},
  name: "Configuracion",
  data() {
    return {
      profile: {},
      profilePhone: "",
      address: "",
      civilState: "",
      optionsCivilState: [
        {value: "soltero", text: "Soltero/a"},
        {value: "casado", text: "Casado/a"},
        {value: "divorciado", text: "Divorciado/a"},
        {value: "concubino", text: "Concubino/a"},
        {value: "otro", text: "Otro"},
      ],
      childsData: [
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
        {name: "", date: ""},
      ],
      childs: 0,
      optionsChilds: [
        {value: 0, text: "0"},
        {value: 1, text: "1"},
        {value: 2, text: "2"},
        {value: 3, text: "3"},
        {value: 4, text: "4"},
        {value: 5, text: "5"},
        {value: 6, text: "6"},
        {value: 7, text: "7"},
        {value: 8, text: "8"},
        {value: 9, text: "9"},
      ],
      optionsGender: [
        {value: "masculino", text: "Masculino"},
        {value: "Femenino", text: "Femenino"},
        {value: "No especifica", text: "No especifica"},
      ],
      loadingLocal: false,
      file: null,
      urlTemp: null,
      sendError: false,
      send: false,
      sendErrorFamily: false,
      sendFamily: false,
      myCroppa: {},
    };
  },
  validations: {
    profilePhone: {
      required,
      maxLength: maxLength(20),
      numeric,
    },
    address: {
      required,
      maxLength: maxLength(50),
    },
    civilState: {
      required,
    },
    childs: {
      required,
    },
  },
  computed: {
    ...mapState(["usuario", "carga"]),
    desactivar() {
      return !this.$v.civilState.$invalid && !this.$v.childs.$invalid;
    },
  },
  methods: {
    updateFamily() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .doc(this.usuario.uid)
          .update({
            civilState: this.civilState,
            childs: this.childs,
            childsArray: this.childsData,
          })
          .then((doc) => {
            this.sendFamily = true;
            this.sendErrorFamily = false;
            this.loadingLocal = false;
            this.pending = false;
          })
          .catch((error) => {
            this.sendFamily = true;
            this.sendErrorFamily = true;
            this.loadingLocal = false;
          });
      }
    },
    async updateProfile() {
      this.loadingLocal = true;
      this.myCroppa.generateBlob(
        async (blob) => {
          var file = new File([blob], "profile");
          this.file = file;
          try {
            if (this.myCroppa._data.chosenFile !== null) {
              const refImagen = storage
                .ref()
                .child(this.usuario.uid)
                .child("avatar");
              const res = await refImagen.put(this.file);
              const url = await refImagen.getDownloadURL();
              this.usuario.foto = url;
              await db
                .collection("users")
                .doc(this.usuario.uid)
                .update({
                  foto: url,
                  phone: this.profilePhone,
                  address: this.address,
                });
              this.file = null;
            } else {
              await db
                .collection("users")
                .doc(this.usuario.uid)
                .update({
                  phone: this.profilePhone,
                  address: this.address,
                });
            }
            this.send = true;
            this.sendError = false;
            this.loadingLocal = false;
          } catch (error) {
            this.send = true;
            this.sendError = true;
            this.loadingLocal = false;
          }
        },
        "image/jpeg",
        0.8
      ); // 80% compressed jpeg file
      // this.file = this.myCroppa.generateDataUrl()
    },
    getProfile() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .doc(this.usuario.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const profileTemp = {
                email: this.usuario.email,
                uid: this.usuario.uid,
                name: doc.data().name,
                surname: doc.data().surname,
                role: doc.data().role,
                number: doc.data().number,
                state: doc.data().state,
                dni: doc.data().dni,
                foto: doc.data().foto,
                phone: doc.data().phone,
                address: doc.data().address,
                civilState: doc.data().civilState,
                childs: doc.data().childs,
                childsArray: doc.data().childsArray,
              };

              this.profile.email = profileTemp.email;
              this.profile.uid = profileTemp.uid;
              this.profile.surname = profileTemp.surname;
              this.profile.role = profileTemp.role;
              this.profile.number = profileTemp.number;
              this.profile.state = profileTemp.state;
              this.profile.dni = profileTemp.dni;
              this.profile.name = profileTemp.name;
              this.profile.foto = profileTemp.foto;
              this.profilePhone = profileTemp.phone;
              this.address = profileTemp.address;
              this.civilState = profileTemp.civilState;
              this.childs = profileTemp.childs;
              this.childsData = profileTemp.childsArray;
              this.loadingLocal = false;
            } else {
              // doc.data() will be undefined in this case
              this.loadingLocal = false;
            }
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
  },
  created() {
    this.getProfile();
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.profile-picture {
  width: 250px;
  height: 250px;
  position: relative;
  &__img {
    width: 100%;
    height: 100%;
  }
  .croppa-container {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    height: 250px;
    &.croppa--has-target {
      .icon-remove {
        position: absolute !important;
        top: 0;
        right: 0;
      }
    }
  }
}
.rotate {
  transform: scaleX(-1);
}
</style>
