<template>
  <div class="topbar d-flex align-items-center fixed-top">
    <div class="w-100 d-flex align-items-center justify-content-end">
      <img class="logo" src="../assets/logo-ammpa-wide.svg" alt="Logo AMMPA" />
      <Sidebar />
      <div class="topbar__buttons">
        <router-link to="/" class="topbar__buttons__button">
          <font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon>
        </router-link>
        <button id="popover-target-1"  :class="{ 'topbar__buttons__button true' : notificationsArray.length > 0 , 'topbar__buttons__button' : notificationsArray.length == 0 }">
          <font-awesome-icon :icon="['fas', 'bell']"></font-awesome-icon>
        </button>
        <b-popover target="popover-target-1" triggers="hover" placement="top">
          <template #title>Notificaciones</template>
          <p  v-if="notificationsArray.length == 0">No hay notificaciones</p>
          <div v-for="(notification, index) in notificationsArray" :key="index" class="notifications">
            <span v-if="notification.state"> {{ notification.message }}</span
            ><button @click="deleteNotification(notification.id, index)" class="notifications__button">
               <font-awesome-icon :icon="['fas', 'times-circle']"></font-awesome-icon>
            </button>
          </div>
        </b-popover>
      </div>
      <div class="topbar__user">
        <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
          <b-dropdown class=" topbar__user__button" right :text="usuario.name">
            <b-dropdown-item to="/configuracion">Editar Perfil</b-dropdown-item>
            <b-dropdown-item @click="cerrarSesion">
              Cerrar sesión
            </b-dropdown-item>
          </b-dropdown>
        </b-button-toolbar>
        <div class="topbar__user__image">
          <img v-if="usuario.foto == ''" src="../assets/avatar.png" alt="perfil" class="profile-picture" />
          <img v-if="usuario.foto != ''" :src="usuario.foto" alt="perfil" class="profile-picture" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from "../firebase";
import Sidebar from "./Sidebar.vue";
import {mapState, mapActions} from "vuex";
export default {
  name: "TopBar",
  data() {
    return {
      notificationsArray: [],
      loadingLocal: false,
    };
  },
  components: {Sidebar},
  computed: {
    ...mapState(["usuario"]),
  },
  methods: {
    ...mapActions(["cerrarSesion"]),
    deleteNotification(notification, index) {
      this.loadingLocal = true;
      db.collection("users")
        .doc(this.usuario.uid)
        .collection("notificaciones")
        .doc(notification)
        .delete()
        .then(() => {
            this.notificationsArray.splice(index, 1)
            this.loadingLocal = false;
          
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
    },
    getNotifications() {
      this.loadingLocal = true;
      db.collection("users")
        .doc(this.usuario.uid)
        .collection("notificaciones")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {

            let item = {message: doc.data().message, state: doc.data().state, id: doc.id};
            this.notificationsArray.push(item);
            this.loadingLocal = false;
          });
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.topbar {
  background: #00a9f2;
  color: #fff;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  z-index: 1000;
  transition: all 0.5s;
  .logo {
    margin: auto;
    margin-left: 80px;
    width: 80px;
  }
  &__scrolled {
    top: -60px;
  }
  i {
    padding-right: 6px;
    line-height: 0;
  }
  &__user {
    position: relative;
    &__button {
      .btn.dropdown-toggle.btn-secondary {
        background-color: $primaryColor !important;
        padding: 1rem;
        padding-left: 50px !important;
        border-radius: 0 !important;
        height: 60px;
        font-family: $primary-font;
        font-weight: $semi-bold;
        text-overflow: ellipsis;
        width: 120px;
        overflow: hidden;
        &:hover {
          background-color: $green !important;
        }
      }
    }
    &__image {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 10;
      overflow: hidden;
      img.profile-picture {
        width: 35px;
        height: 35px;
      }
    }
  }
  &__buttons {
    &__button {
      position: relative;
      background-color: transparent;
      border: none;
      padding: 1rem 0.5rem;
      color: #fff;
      font-size: 1.4rem!important;
      &:hover {
        color: $primaryColor;
      }
      &.disabled {
        cursor: initial;
        opacity: 0.5;
        &:hover {
          color: #fff;
        }
      }
      &.true {
        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: $secondaryColorHover;
          border-radius: 50%;
          position: absolute;
          left: 20px;
          top: 20px;
        }
      }
    }
  }
}
.notifications{
  position: relative;
  padding-right: 25px!important;
  padding: .5rem 0;
  border-bottom: 1px solid #E6E6E6;
  &:last-child{
   border-bottom: none; 
  }
  &__button{
    font-size: 1.2rem;
    position: absolute;
    top: 7px;
    right: 0;
    color: $secondaryColorHover;
    background-color: transparent;
    border: 0;
  }
}

@media screen and (min-width: 996px) {
  .topbar {
    .logo {
      margin: auto;
      margin-left: 20px;
      width: 120px;
    }
    &__user {
      position: relative;
      &__button {
        .btn.dropdown-toggle.btn-secondary {
          padding-left: 60px !important;
          width: auto;
          overflow: hidden;
          &:hover {
            background-color: $green !important;
          }
        }
      }
      &__image {
        left: 15px;
        top: 10px;
      }
    }
  }
}
</style>
