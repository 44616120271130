<template>
    <div>
        <form @submit.prevent="agregarNoticia()" enctype="multipart/form-data">
            <h3>Agregar Noticia</h3>
            <input type="text" class="form-control my-2 " placeholder="Titulo" v-model="noticia.titulo">
            <input type="text" class="form-control my-2 " placeholder="Categoria" v-model="noticia.categoria">
            <input type="text" class="form-control my-2 " placeholder="Texto" v-model="noticia.texto">
            <input type="file" name="file" @change="buscarImagen($event)" >
            <button type="submit">AGREGAR</button>

        </form>
    </div>
</template>

<script>
export default {
    data(){
        return{
            noticia: {titulo: '', url: '', categoria: '', texto: '', file: null},
            formdata : null,
            
        }
    },
    methods: {
        agregarNoticia(){
            this.noticia.url = this.noticia.titulo.replace(" ","-");
            this.formdata = new FormData();
            this.formdata.append("titulo", this.noticia.titulo);
            this.formdata.append("url", this.noticia.url);
            this.formdata.append("categoria", this.noticia.categoria);
            this.formdata.append("texto", this.noticia.texto);
            this.formdata.append("file", this.noticia.file);
            
            this.axios.post('http://localhost:3000/noticia', this.formdata)
                .then(res => {
                    this.noticia.titulo = '';
                    this.noticia.categoria = '';
                    this.noticia.texto = '';
                })
                .catch(e => {
                })
        },
        buscarImagen(e){
            this.noticia.file = event.target.files[0];

        }
    }
}
</script>