<template>
  <section class="hero">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        img-src="https://mutualmercantil.ar/img-static/slide-1.jpg"
      >
        <div class="container slideContent">
          <h2>Registrate a nuestra web <span>AMMPA</span></h2>
          <p>
            Ya se encuentra disponible el registro para los asociados de la
            mutual mercantil, ingresa tus datos y accede a muchos beneficios.
          </p>

          <router-link to="/registro" class="btn-get-started scrollto"
            >Registrate</router-link
          >
        </div>
      </b-carousel-slide>
      <b-carousel-slide
        
        img-src="https://mutualmercantil.ar/img-static/slide-2.jpg"
      >
        <div class="container slideContent">
          <h2>Ingresa y descargá tu Credencial <span>AMMPA</span></h2>
          <p>
            Podes descarcar tu credencial de asociado accediendo a "Mi Cuenta".
          </p>

          <router-link to="/mi-cuenta" class="btn-get-started scrollto"
            >Ingresar</router-link
          >
        </div>
      </b-carousel-slide>
      <b-carousel-slide
        
        img-src="https://mutualmercantil.ar/img-static/slide-3.jpg"
      >
        <div class="container slideContent">
          <h2>Beneficios <span>AMMPA</span></h2>
          <p>
            Conocé todos los beneficios para los asociados.
          </p>

          <router-link to="/registro" class="btn-get-started scrollto"
            >Ver más</router-link
          >
        </div>
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>

<script>
export default {
  name: "Slide",
  data() {
    return {
      slide: 0,
      sliding: null,
      sliders: [],
    };
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.hero{
    height: calc(100vh - 250px);
    .carousel {
        height: 100%;
        .carousel-inner{
            height: 100%;
            .carousel-item{
                height: 100%;
                background-color: #00a9f2!important;
                .carousel-caption{
                  bottom: 0;
                  padding-bottom: 0;
                }
            }
        }
    }
.slideContent {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-top: 4px solid #00a9f2;
  h2 {
    color: #2f2f2f;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    text-shadow: none !important;
  }
  p {
    margin: 0 auto 5px auto!important;
    color: #555555;
    text-shadow: none !important;
  }
  .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 32px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    color: #fff;
    background: #00a9f2;
    text-shadow: none !important;
  }
}
} 

@media screen and (min-width: 768px) {
  .hero{
    .slideContent {
      h2{
        font-size: 36px;
      }
    }
  }
  
}

</style>
