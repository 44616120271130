<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Mis Beneficios</h2>
      <div class="col-12 px-2 ">
        <div v-for="(item, index) in categoryArray" :key="index">
        <router-link :to="{name: 'Beneficio', params: {id: item.value}}" >
        
          <div class="col-12 col-md-6 px-0 px-2">
            <!-- <div class="col-12 col-md-6 px-0 px-2" v-if="item.to === 'todos' || item.to === profileEmployer"> -->
            <div class="divCard card-alerts  flex-column" :class="item.value">
              <div class="d-flex">
                <div class="icon">
                  <font-awesome-icon :icon="['fas', 'heartbeat']" v-if="item.value === 'salud'"></font-awesome-icon>
                <font-awesome-icon :icon="['fas', 'glasses']" v-if="item.value === 'optica'"></font-awesome-icon>
                <font-awesome-icon :icon="['fas', 'plane']" v-if="item.value === 'turismo'"></font-awesome-icon>
                <font-awesome-icon :icon="['fas', 'plus-square']" v-if="item.value === 'farmacias'"></font-awesome-icon>
                <font-awesome-icon :icon="['fas', 'cross']" v-if="item.value === 'sepelio'"></font-awesome-icon>
                <font-awesome-icon :icon="['fas', 'broadcast-tower']" v-if="item.value === 'radio'"></font-awesome-icon>
                <font-awesome-icon :icon="['fas', 'plus']" v-if="item.value === 'generales'"></font-awesome-icon>
                </div>
                <div class="p-2 pl-4 ">
                  <h5 class="card-alerts_title">Beneficios {{ item.name }}</h5>
                </div>
              </div>
            </div>
          </div>
        
        </router-link>
        </div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {db, storage} from "../firebase";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "Recibos",
  components: {LayoutPanel},
  data() {
    return {
      categoryArray: [],
      profileEmployer: "",
      loadingLocal: false,
    };
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
  },
  methods: {
    getCategories() {
      this.categoryArray = [];
      this.loadingLocal = true;
      db.collection("beneficios-category")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let item = {value: doc.data().value, name: doc.data().name};
            this.categoryArray.push(item);
            this.loadingLocal = false;
          });
        })
        .catch((error) => {
          this.loadingLocal = false;
        });
      this.loadingLocal = false;
    },
    getProfile() {
      this.loadingLocal = true;
      if (this.usuario !== null) {
        db.collection("users")
          .doc(this.usuario.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const profileTemp = {
                employer: doc.data().employer,
              };
              this.profileEmployer = profileTemp.employer;
              this.loadingLocal = false;
            } else {
              // doc.data() will be undefined in this case
              this.loadingLocal = false;
            }
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      }
    },
  },
  created() {
    this.getCategories();
    this.getProfile();
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.category {
  text-transform: uppercase;
}
.card-alerts_detail{
  padding-left: 4.5rem;
}
.icon{
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
