<template>
  <LayoutPanel>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <h2 class="page__title">Notificaciones</h2>
      <div class="col-12 px-2">
        <div class="divCard" v-if="usuario.role != 'admin'">
          <div class="msgInvalid">
            <font-awesome-icon :icon="['fas', 'user-slash']"></font-awesome-icon>
            <h3>Sin permisos</h3>
            <p>
              Su usuario no tiene permiso suficiente para ver este contenido.
            </p>
          </div>
        </div>
        <div class="divCard" v-if="usuario.role == 'admin'">
          <div class="col-12 px-0">
            <h3 class="divCard__title">Enviar Notificaciones</h3>
            <div>
              <form @submit.prevent="sendNotification()" class="d-flex flex-column">
                <div class="col-12 d-flex flex-wrap align-items-center">
                  <div class="form-group col-12 col-md-6 ">
                    <label for="name">Mensaje</label>
                    <textarea
                      type="text-area"
                      class="form-control"
                      id="message"
                      v-model.trim="$v.message.$model"
                      :class="{'is-invalid': $v.message.$error}"
                      required
                    ></textarea>
                    <small class="text-danger msg-error" v-if="!$v.message.maxLength">Máximo 500 carácteres</small>
                  </div>
                  <div class="form-group col-12 col-md-3">
                    <label for="category">Destinatarios</label>
                    <b-form-select v-model.trim="$v.to.$model" :options="toOptions" id="to" class="form-control" required></b-form-select>
                  </div>
                  <div class="col-12 col-md-3 pr-md-2 my-3">
                    <button type="submit" class="btn btn-primary btn-block mt-0" :class="{disabled: !desactivar}" :disabled="!desactivar">
                      Enviar
                    </button>
                  </div>
                </div>

                <div class="col-12 col-md-9 pl-md-4">
                  <div
                    v-if="send"
                    :class="{'alert alert-success w-100 mt-3 mt-md-1 mb-0': !sendError, 'alert alert-danger w-100 mt-3 mt-md-1 mb-0': sendError}"
                    role="alert"
                  >
                    <p class="mb-0" v-if="sendError">Error al guardar los datos, vuelva a intentarlo en unos instantes</p>
                    <p class="mb-0" v-if="!sendError">Datos guardados exitosamente</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {db, storage} from "../firebase";
import {required, email, sameAs, minLength, maxLength, numeric} from "vuelidate/lib/validators";
import LayoutPanel from "../components/LayoutPanel.vue";
export default {
  name: "Notificaciones",
  data() {
    return {
      message: null,
      to: "",
      toOptions: [
        {value: "todos", text: "Todos"},
        {value: "agec", text: "Socio Agec"},
        {value: "mutual", text: "Socio Mutual"},
      ],
      send: false,
      sendError: false,
      loadingLocal: false,
    };
  },
  validations: {
    to: {
      required,
    },
    message: {
      required,
      maxLength: maxLength(500),
    },
  },
  components: {LayoutPanel},
  computed: {
    ...mapState(["usuario", "carga"]),
    desactivar() {
      return !this.$v.to.$invalid && !this.$v.message.$invalid;
    },
  },
  methods: {
    async sendNotification() {
      this.loadingLocal = true;
      let date = new Date();
      let dateTmp = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + "-" + date.getSeconds();
      await db
        .collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().employer === this.to || this.to === "todos") {
              db.collection("users")
                .doc(doc.id)
                .collection("notificaciones")
                .doc(dateTmp)
                .set({
                  message: this.message,
                  state: true,
                });
              var formData = new FormData();
              formData.append("email", doc.data().mail);
              formData.append("message", this.message);
              if (doc.data().state === 'activo') {
                fetch("https://mutualmercantil.ar/post-notification.php", {
                  method: "POST",
                  body: formData,
                });
              }
            }
          });
          this.sendError = false;
          this.send = true;
          this.loadingLocal = false;
        })
        .catch((error) => {
          this.loadingLocal = false;
          this.sendError = true;
          this.send = true;
        });
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "../scss/styles.scss";
</style>
