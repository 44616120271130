<template>
  <div>
    <div v-if="loadingLocal || carga" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!carga" class="page">
      <div class="col-12 px-2 d-flex align-items-center justify-content-center h-100">
        <div class="credencial" v-if="profileState == 'activo'">
          <div class="credencial__header">
            <img class="credencial__header__image" src="../assets/logo-ammpa-white.svg" alt="Logo AMMPA" />
          </div>
          <div class="credencial__body">
            <p class="credencial__body__name">{{ profileSurname }}</p>
            <p class="credencial__body__name">{{ profileName }}</p>
            <p class="credencial__body__text">DNI {{ profileDni }}</p>
            <p class="credencial__body__socio" :class="profileState">SOCIO {{ profileState }}</p>
            <div class="credencial__body__image d-flex py-3">
              <div class="col-12 px-0 text-center">
                <img v-if="profileFoto == ''" src="../assets/avatar.png" alt="perfil" class="profile-picture" />
                <img v-if="profileFoto != ''" :src="profileFoto" alt="perfil" class="profile-picture" />
              </div>
            </div>
            <p class="credencial__body__nro-socio">SOCIO {{ profileNumber }}</p>
            <p class="credencial__body__nro-socio text-info" v-if="usuario.employer === 'Socio Adherente'"><b>ADHERENTE</b></p>
          </div>
        </div>
        <div class="credencial inactive d-flex justify-content-center flex-column" v-if="profileState == 'inactivo'">
          <h2 class="text-center text-white">SOCIO</h2>
          <h2 class="text-center text-white">INACTIVO</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {db} from "../firebase";
export default {
  name: "Verificacion",
  data() {
    return {
      id: this.$route.params.id,
      profileName: "",
      profileSurname: "",
      profileEmail: "",
      profileState: "",
      profileDni: "",
      profileNumber: "",
      profileFoto:"",
      loadingLocal: false,
      sendError: false,
      send: false,
    };
  },
  computed: {
    ...mapState(["carga"]),
  },
  methods: {
    getProfile() {
      this.loadingLocal = true;
        db.collection("users")
          .doc(this.$route.params.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const profileTemp = {
                email: doc.data().mail,
                uid: this.$route.params.id,
                name: doc.data().name,
                surname: doc.data().surname,
                role: doc.data().role,
                number: doc.data().number,
                state: doc.data().state,
                dni: doc.data().dni,
                foto: doc.data().foto
              };
              this.profileEmail = profileTemp.email;
              this.profileSurname = profileTemp.surname;
              this.profileRole = profileTemp.role;
              this.profileNumber = profileTemp.number;
              this.profileDni = profileTemp.dni;
              this.profileName = profileTemp.name;
              this.profileState = profileTemp.state;
              this.profileFoto = profileTemp.foto;
              this.loadingLocal = false;
            } else {
              this.loadingLocal = false;
            }
          })
          .catch((error) => {
            this.loadingLocal = false;
          });
      
    },
  },
  created() {
    this.getProfile();
  },
};
</script>
<style lang="scss" scoped>
@import "../scss/styles.scss";
.page{
  height: 100vh;
}
.credencial {
  background-color: $agecColor;
  border-radius: 15px;
  width: 250px;
  height: 480px;
  padding: 10px;
  &__header {
    height: 90px;
    width: 100%;
    overflow: hidden;
    &__image {
      width: 220px;
    }
  }
  &__body {
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    height: 370px;
    width: 100%;
    font-family: $primary-font;
    color: #1a1a1a;
    padding: 10px;
    &__text {
      font-weight: $normal;
      margin-bottom: 0;
      font-size: $small;
      color: $gray;
    }
    &__name {
      font-weight: $bold;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    &__nro-socio {
      font-weight: $bold;
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: center;
    }
    &__socio {
      font-weight: $bold;
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: $small;
      &::after {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 5px;
        content: "";
        position: absolute;
        margin-top: 7px;
        color: $gray;
      }
      &.activo {
        &::after {
          background-color: #10e669;
        }
      }
      &.inactivo {
        &::after {
          background-color: #e63010;
        }
      }
    }
    &__image {
      img {
        width: 200px;
        height: 200px;
      }
    }
  }
  &.inactive{
    background-color: $statusRed;
  }
}
</style>
