<template>
  <b-navbar toggleable="lg">
    <b-navbar-toggle target="nav-collapse">
      <div
        id="menu-toggle"
        :class="{ open: openMenu }"
        @click="openMenu = !openMenu"
      >
        <div id="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div id="cross">
          <span></span>
          <span></span>
        </div>
      </div>
    </b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav end class="container">
        <b-nav-item>HOME</b-nav-item>
        <b-nav-item>SERVICIOS</b-nav-item>
        <b-nav-item>BENEFICIOS</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Navbar",
  data() {
    return {
      openMenu: false,
    };
  },
  computed: {
    ...mapState(["links"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.navbar-dark .navbar-toggler {
  border: none;
  padding: 0;
  margin-top: -15px;
}
.navbar-dark .navbar-toggler:focus {
  outline: none;
}

#menu-toggle span {
  display: block;
  background: $black;
  border-radius: 2px;
}

#menu-toggle {
  width: 50px;
  height: 50px;
  margin: 0;
  position: relative;
  position: relative;
  cursor: pointer;
}
#menu-toggle #hamburger {
  position: absolute;
  height: 100%;
  width: 100%;
}
#menu-toggle #hamburger span {
  width: 40px;
  height: 6px;
  position: relative;
  margin: 6px 0;
  border-radius: 25px;
  transition: all 0.2s ease-in-out;
}
#menu-toggle #hamburger span:nth-child(1) {
  transition-delay: 0.5s;
}
#menu-toggle #hamburger span:nth-child(2) {
  transition-delay: 0.625s;
}
#menu-toggle #hamburger span:nth-child(3) {
  transition-delay: 0.75s;
}
#menu-toggle #cross {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotate(45deg);
}
#menu-toggle #cross span {
  transition: all 0.2s ease-in-out;
}
#menu-toggle #cross span:nth-child(1) {
  height: 0%;
  width: 6px;
  border-radius: 25px;
  position: absolute;
  top: 6%;
  left: 20px;
  transition-delay: 0s;
}
#menu-toggle #cross span:nth-child(2) {
  width: 0%;
  height: 6px;
  border-radius: 25px;
  position: absolute;
  left: 6%;
  top: 20px;
  transition-delay: 0.25s;
}

#menu-toggle.open #hamburger span {
  width: 0%;
}
#menu-toggle.open #hamburger span:nth-child(1) {
  transition-delay: 0s;
}
#menu-toggle.open #hamburger span:nth-child(2) {
  transition-delay: 0.125s;
}
#menu-toggle.open #hamburger span:nth-child(3) {
  transition-delay: 0.25s;
}
#menu-toggle.open #cross span:nth-child(1) {
  height: 80%;
  transition-delay: 0.625s;
}
#menu-toggle.open #cross span:nth-child(2) {
  width: 80%;
  transition-delay: 0.375s;
}
.navbar {
  justify-content: flex-end !important;
  position: absolute !important;
  top: 20px;
  right: 10px;
  padding: 0.5rem 0!important;
  &-toggler {
    border: none !important;
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }
  .collapse {
    width: 100vw;
    position: absolute;
    background-color: $agecColor;
    top: 85px;
    right: -10px;
  }
  .navbar-nav {
    .nav-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 0.8rem;
      width: 100%;
      text-align: center;
      .nav-link {
        color: rgba(255, 255, 255, 1);
        font-family: $primary-font;
        font-weight: $normal;
        font-size: 1rem;
        padding: 0rem 0.5rem !important;
        text-transform: uppercase;
        &:hover {
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

@media screen and (min-width: 996px) {
  .navbar {
    justify-content: flex-start !important;
    position: relative!important;
    top: auto;
    right: auto;
    .collapse {
      width: auto;
      position: relative;
      background-color: transparent;
      top: auto;
      right: auto;
    }
    .navbar-nav {
      .nav-item {
        border-right: none;
        border-bottom: none;
        flex-grow: 1;
        text-align: center;
        width: auto;
        padding: 0;
        .nav-link {
          font-size: .8rem;
          padding: 0rem 0.5rem !important;
          color: $black;
          font-weight: $semi-bold;
          &:hover {
            color: $agecColor!important;
          }
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
</style>
